var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-content single-resume-upload" },
    [
      _c(
        "div",
        {
          staticClass: "upload-btn-wrap",
          style: _vm.isEdit ? "left: 204px;" : "",
        },
        [
          _c("form", { ref: "upload", staticClass: "file-upload" }, [
            _c(
              "label",
              [
                _c("input", {
                  staticClass: "upload-input",
                  attrs: {
                    type: "file",
                    id: "uploadFile",
                    accept:
                      "application/msword,text/html,application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.uploadFile($event)
                    },
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "upload-file-btn",
                    attrs: { type: "primary", size: "mini" },
                  },
                  [
                    _vm._v(
                      "\n                    上传简历附件\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("el-row", { staticClass: "content-main" }, [
        _c("div", { staticClass: "content-wrap" }, [
          _c(
            "div",
            { staticClass: "content-left" },
            [
              _c(
                "el-form",
                {
                  ref: "resumeForm",
                  attrs: {
                    model: _vm.resumeData,
                    rules: _vm.rules,
                    "label-width": "130px",
                    size: "mini",
                  },
                },
                [
                  _c("div", { staticClass: "resume-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content-block content-basic",
                        attrs: { id: "contentBase" },
                      },
                      [
                        _c("div", { staticClass: "content-title" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("基本信息"),
                          ]),
                          _c("span", { staticClass: "line" }),
                          _vm.resumeData.isExisted
                            ? _c("span", { staticClass: "tips" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tips-link",
                                    attrs: { href: "javascript:;;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.compareResume({
                                          fileName: _vm.fileName
                                            ? _vm.fileName
                                            : "singleEditFile",
                                          candidateId: _vm.resumeData.existedId,
                                          candidate: _vm.resumeData,
                                          errorInfo: _vm.resumeData.errorInfo,
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v("该候选人已存在于人才库。去对比 >>")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "content-inner" },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "first-row",
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "realName",
                                          label: "姓名",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input-item",
                                          attrs: { size: "mini" },
                                          model: {
                                            value: _vm.resumeData.realName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.resumeData,
                                                "realName",
                                                $$v
                                              )
                                            },
                                            expression: "resumeData.realName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "input-gender",
                                          prop: "gender",
                                          label: "性别",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button-group",
                                          {
                                            attrs: { size: "mini" },
                                            model: {
                                              value: _vm.resumeData.gender,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.resumeData,
                                                  "gender",
                                                  $$v
                                                )
                                              },
                                              expression: "resumeData.gender",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "gender-select-btn",
                                                class: {
                                                  active:
                                                    _vm.resumeData.gender == 1,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.changeGender(1)
                                                  },
                                                },
                                              },
                                              [_vm._v("男")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "gender-select-btn",
                                                class: {
                                                  active:
                                                    _vm.resumeData.gender == 2,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.changeGender(2)
                                                  },
                                                },
                                              },
                                              [_vm._v("女")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                (!_vm.isEdit ||
                                  _vm.resumeData.canViewContract ||
                                  _vm.resumeData.mobileStatus == 3) &&
                                !_vm.isLocked
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              id: "input-mobile",
                                              prop: "mobile",
                                              label: "手机",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-item",
                                              attrs: {
                                                size: "mini",
                                                placeholder:
                                                  "国际手机以+或者00开头",
                                              },
                                              nativeOn: {
                                                input: function ($event) {
                                                  return _vm.regexMobile.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.resumeData.mobile,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.resumeData,
                                                    "mobile",
                                                    $$v
                                                  )
                                                },
                                                expression: "resumeData.mobile",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (!_vm.isEdit ||
                                  _vm.resumeData.canViewContract ||
                                  _vm.resumeData.emailStatus == 3) &&
                                !_vm.isLocked
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              id: "input-email",
                                              prop: "email",
                                              label: "邮箱",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-item",
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.resumeData.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.resumeData,
                                                    "email",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "resumeData.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (!_vm.isEdit ||
                                  _vm.resumeData.canViewContract ||
                                  _vm.resumeData.wechatStatus == 3) &&
                                !_vm.isLocked
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              id: "input-wechat",
                                              prop: "wechat",
                                              label: "微信",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-item",
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.resumeData.wechat,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.resumeData,
                                                    "wechat",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "resumeData.wechat",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "yexp-form-item",
                                        attrs: {
                                          id: "input-yearOfExperience",
                                          prop: "yearOfExperience",
                                          label: "工作经验",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            model: {
                                              value:
                                                _vm.resumeData.yearOfExperience,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.resumeData,
                                                  "yearOfExperience",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "resumeData.yearOfExperience",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("年"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "yexp-form-item",
                                        attrs: { prop: "age", label: "年龄" },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            model: {
                                              value: _vm.resumeData.age,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.resumeData,
                                                  "age",
                                                  $$v
                                                )
                                              },
                                              expression: "resumeData.age",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("岁"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: _vm.isPersonalCreate
                                            ? null
                                            : "city",
                                          label: "城市",
                                        },
                                      },
                                      [
                                        _c("el-cascader", {
                                          attrs: {
                                            props: { expandTrigger: "hover" },
                                            options: _vm.cities,
                                            "show-all-levels": false,
                                            clearable: true,
                                            filterable: "",
                                          },
                                          on: { change: _vm.selectCity },
                                          model: {
                                            value: _vm.select.selectedCity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.select,
                                                "selectedCity",
                                                $$v
                                              )
                                            },
                                            expression: "select.selectedCity",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.resumeData.workExperiences.length > 0,
                            expression: "resumeData.workExperiences.length > 0",
                          },
                        ],
                        staticClass: "content-block content-experience",
                        attrs: { id: "contentExperience" },
                      },
                      [
                        _c("div", { staticClass: "content-title" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("工作经历"),
                          ]),
                          _c("span", { staticClass: "line" }),
                          _c(
                            "span",
                            {
                              staticClass: "add-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.addExperience()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "icon-font icon-add" }),
                              _vm._v("添加"),
                            ]
                          ),
                        ]),
                        _vm._l(
                          _vm.resumeData.workExperiences,
                          function (experience, index) {
                            return _c(
                              "div",
                              {
                                key: "experience_" + index,
                                staticClass: "content-inner",
                                attrs: { id: "experience_" + index },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { staticClass: "latest-experience" },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.choseLatest(
                                                      index,
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    experience.isLatestWorkExperience,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      experience,
                                                      "isLatestWorkExperience",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "experience.isLatestWorkExperience",
                                                },
                                              },
                                              [_vm._v("设为最近工作经历")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "workExperiences." +
                                                index +
                                                ".company",
                                              label: "公司",
                                              rules:
                                                _vm.rules.experienceRule
                                                  .company,
                                            },
                                          },
                                          [
                                            _vm.isPersonalCreate
                                              ? _c("el-input", {
                                                  staticClass: "input-item",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: experience.company,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        experience,
                                                        "company",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "experience.company",
                                                  },
                                                })
                                              : _vm._e(),
                                            (experience.company ||
                                              experience.company == "" ||
                                              experience.isAdd ||
                                              !_vm.candidateId) &&
                                            !_vm.isPersonalCreate
                                              ? _c("customer-select-or-write", {
                                                  attrs: {
                                                    size: "mini",
                                                    customerId:
                                                      experience.customerid,
                                                    customerName:
                                                      experience.company,
                                                  },
                                                  on: {
                                                    "update:customerId":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          experience,
                                                          "customerid",
                                                          $event
                                                        )
                                                      },
                                                    "update:customer-id":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          experience,
                                                          "customerid",
                                                          $event
                                                        )
                                                      },
                                                    "update:customerName":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          experience,
                                                          "company",
                                                          $event
                                                        )
                                                      },
                                                    "update:customer-name":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          experience,
                                                          "company",
                                                          $event
                                                        )
                                                      },
                                                    "finish-callback":
                                                      function ($event) {
                                                        return _vm.finishCallback(
                                                          index
                                                        )
                                                      },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        experience.isLatestWorkExperience &&
                                        _vm.showDep(
                                          experience,
                                          _vm.resumeData.customerId
                                        )
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "workExperiences." +
                                                    index +
                                                    ".customerDepartmentName",
                                                  label: "部门",
                                                  rules:
                                                    _vm.rules.experienceRule
                                                      .customerDepartmentName,
                                                },
                                              },
                                              [
                                                _c(
                                                  "customer-department-select",
                                                  {
                                                    ref: "departmentSelect",
                                                    refInFor: true,
                                                    staticClass:
                                                      "resumeData-department-select",
                                                    attrs: {
                                                      size: "mini",
                                                      customerDepartmentName:
                                                        _vm.resumeData
                                                          .customerDepartmentName,
                                                      canSrcoll: false,
                                                      customerId:
                                                        _vm.resumeData
                                                          .customerId,
                                                      departmentId:
                                                        _vm.resumeData
                                                          .customerDepartmentId,
                                                      placeholder:
                                                        '输入完整路径，多级部门用"，"分隔',
                                                    },
                                                    on: {
                                                      "update-department":
                                                        function ($event) {
                                                          return _vm.updateDepartment(
                                                            $event,
                                                            index
                                                          )
                                                        },
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "start-date-wrap",
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "workExperiences." +
                                                index +
                                                ".dateRange",
                                              rules:
                                                _vm.rules.experienceRule
                                                  .dateRange,
                                              label: "时间",
                                              required:
                                                _vm.isEdit &&
                                                !_vm.isPersonalCreate,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "date-picker-pannel",
                                              },
                                              [
                                                _c("font-icon", {
                                                  attrs: {
                                                    href: "#icon-calendar",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-picker-wrap",
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      attrs: {
                                                        type: "month",
                                                        placeholder: "开始时间",
                                                        "picker-options":
                                                          _vm.setExperienceStartDateOption(
                                                            experience
                                                          ),
                                                      },
                                                      on: {
                                                        change: (picker) =>
                                                          _vm.dateBlur(
                                                            picker,
                                                            experience,
                                                            "start"
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          experience.startDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            experience,
                                                            "startDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "experience.startDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "date-picker-split",
                                                  },
                                                  [_vm._v("~")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-picker-wrap",
                                                  },
                                                  [
                                                    experience.endDate &&
                                                    experience.endDate
                                                      .toString()
                                                      .indexOf("9999") > -1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "date-tonow",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        至今\n                                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    experience.endDate &&
                                                    experience.endDate
                                                      .toString()
                                                      .indexOf("9999") > -1
                                                      ? _c("el-date-picker", {
                                                          staticClass:
                                                            "opacity-date-picker",
                                                          attrs: {
                                                            type: "month",
                                                            placeholder:
                                                              "结束时间",
                                                            "picker-options":
                                                              _vm.setExperienceEndDateOption(
                                                                experience
                                                              ),
                                                          },
                                                          on: {
                                                            change: (picker) =>
                                                              _vm.dateBlur(
                                                                picker,
                                                                experience,
                                                                "end"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              experience.endDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                experience,
                                                                "endDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "experience.endDate",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    !experience.endDate ||
                                                    experience.endDate
                                                      .toString()
                                                      .indexOf("9999") === -1
                                                      ? _c("el-date-picker", {
                                                          attrs: {
                                                            type: "month",
                                                            placeholder:
                                                              "结束时间",
                                                            "picker-options":
                                                              _vm.setExperienceEndDateOption(
                                                                experience
                                                              ),
                                                          },
                                                          on: {
                                                            change: (picker) =>
                                                              _vm.dateBlur(
                                                                picker,
                                                                experience,
                                                                "end"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              experience.endDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                experience,
                                                                "endDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "experience.endDate",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "workExperiences." +
                                                index +
                                                ".title",
                                              label: "职位",
                                              rules:
                                                _vm.rules.experienceRule.title,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-item",
                                              attrs: { size: "mini" },
                                              model: {
                                                value: experience.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    experience,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "experience.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "workExperiences." +
                                                index +
                                                ".location",
                                              label: "地区",
                                            },
                                          },
                                          [
                                            _c("el-cascader", {
                                              attrs: {
                                                props: {
                                                  expandTrigger: "hover",
                                                },
                                                options: _vm.cities,
                                                "show-all-levels": false,
                                                clearable: true,
                                              },
                                              model: {
                                                value: experience.locationArr,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    experience,
                                                    "locationArr",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "experience.locationArr",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "describe",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "workExperiences." +
                                                index +
                                                ".description",
                                              label: "内容",
                                              rules:
                                                _vm.rules.experienceRule
                                                  .description,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                type: "textarea",
                                                rows: 5,
                                                placeholder: "请输入工作内容",
                                                maxlength: "8000",
                                                "show-word-limit": "",
                                              },
                                              model: {
                                                value: experience.description,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    experience,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "experience.description",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isPersonalCreate ||
                                (!_vm.isPersonalCreate && index > 0)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "delete-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelExperience(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-ic_rubbish" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm.resumeData.educationExperiences.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-education",
                            attrs: { id: "contentEducation" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("教育经历"),
                              ]),
                              _c("span", { staticClass: "line" }),
                              _c(
                                "span",
                                {
                                  staticClass: "add-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addEducation()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _vm._v("添加"),
                                ]
                              ),
                            ]),
                            _vm._l(
                              _vm.resumeData.educationExperiences,
                              function (experience, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "content-inner",
                                    attrs: { id: "education_" + index },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "first-row",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "university-chose",
                                                attrs: {
                                                  prop:
                                                    "educationExperiences." +
                                                    index +
                                                    ".school",
                                                  label: "学校",
                                                  rules:
                                                    _vm.rules.educationRule
                                                      .school,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "allow-create": "",
                                                    },
                                                    on: {
                                                      change: (select) =>
                                                        _vm.selectChange(
                                                          select,
                                                          experience,
                                                          "school"
                                                        ),
                                                    },
                                                    model: {
                                                      value: experience.school,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          experience,
                                                          "school",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "experience.school",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.universityList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "时间",
                                                  prop:
                                                    "educationExperiences." +
                                                    index +
                                                    ".dateRange",
                                                  rules:
                                                    _vm.rules.educationRule
                                                      .dateRange,
                                                  required:
                                                    _vm.isEdit &&
                                                    !_vm.isPersonalCreate,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-picker-pannel",
                                                  },
                                                  [
                                                    _c("font-icon", {
                                                      attrs: {
                                                        href: "#icon-calendar",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "date-picker-wrap",
                                                      },
                                                      [
                                                        _c("el-date-picker", {
                                                          attrs: {
                                                            type: "month",
                                                            placeholder:
                                                              "开始时间",
                                                            "picker-options":
                                                              _vm.setEducationStartDateOption(
                                                                experience
                                                              ),
                                                          },
                                                          on: {
                                                            change: (picker) =>
                                                              _vm.dateBlur(
                                                                picker,
                                                                experience,
                                                                "start"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              experience.startDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                experience,
                                                                "startDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "experience.startDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date-picker-split",
                                                      },
                                                      [_vm._v("~")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "date-picker-wrap",
                                                      },
                                                      [
                                                        experience.endDate &&
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") > -1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "date-tonow",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        至今\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        experience.endDate &&
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") > -1
                                                          ? _c(
                                                              "el-date-picker",
                                                              {
                                                                staticClass:
                                                                  "opacity-date-picker",
                                                                attrs: {
                                                                  type: "month",
                                                                  placeholder:
                                                                    "结束时间",
                                                                  "picker-options":
                                                                    _vm.setExperienceEndDateOption(
                                                                      experience
                                                                    ),
                                                                },
                                                                on: {
                                                                  change: (
                                                                    picker
                                                                  ) =>
                                                                    _vm.dateBlur(
                                                                      picker,
                                                                      experience,
                                                                      "end"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    experience.endDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        experience,
                                                                        "endDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "experience.endDate",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        !experience.endDate ||
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") ===
                                                          -1
                                                          ? _c(
                                                              "el-date-picker",
                                                              {
                                                                attrs: {
                                                                  type: "month",
                                                                  placeholder:
                                                                    "结束时间",
                                                                  "picker-options":
                                                                    _vm.setEducationEndDateOption(
                                                                      experience
                                                                    ),
                                                                },
                                                                on: {
                                                                  change: (
                                                                    picker
                                                                  ) =>
                                                                    _vm.dateBlur(
                                                                      picker,
                                                                      experience,
                                                                      "end"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    experience.endDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        experience,
                                                                        "endDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "experience.endDate",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "degree-chose",
                                                attrs: {
                                                  label: "学历",
                                                  prop:
                                                    "educationExperiences." +
                                                    index +
                                                    ".degree",
                                                  rules:
                                                    _vm.rules.educationRule
                                                      .degree,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "请选择",
                                                    },
                                                    model: {
                                                      value: experience.degree,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          experience,
                                                          "degree",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "experience.degree",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.degreeArr,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                          disabled:
                                                            item.label ==
                                                            "未知",
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "major-chose",
                                                attrs: {
                                                  label: "专业",
                                                  prop:
                                                    "educationExperiences." +
                                                    index +
                                                    ".major",
                                                  rules:
                                                    _vm.rules.educationRule
                                                      .major,
                                                  required:
                                                    _vm.isEdit &&
                                                    !_vm.isPersonalCreate,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "allow-create": "",
                                                    },
                                                    on: {
                                                      change: (select) =>
                                                        _vm.selectChange(
                                                          select,
                                                          experience,
                                                          "major"
                                                        ),
                                                    },
                                                    model: {
                                                      value: experience.major,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          experience,
                                                          "major",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "experience.major",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.majorList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isPersonalCreate ||
                                    (!_vm.isPersonalCreate && index != 0)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "delete-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelEducation(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: {
                                                href: "#icon-ic_rubbish",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.resumeData.projectExperiences.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-project",
                            attrs: { id: "contentProject" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("项目经验"),
                              ]),
                              _c("span", { staticClass: "line" }),
                              _c(
                                "span",
                                {
                                  staticClass: "add-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addProject()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _vm._v("添加"),
                                ]
                              ),
                            ]),
                            _vm._l(
                              _vm.resumeData.projectExperiences,
                              function (experience, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "content-inner",
                                    attrs: { id: "project_" + index },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "first-row",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "项目名称",
                                                  prop:
                                                    "projectExperiences." +
                                                    index +
                                                    ".projectName",
                                                  rules:
                                                    _vm.rules.projectRule
                                                      .projectName,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "input-item",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      experience.projectName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        experience,
                                                        "projectName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "experience.projectName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "时间",
                                                  prop:
                                                    "projectExperiences." +
                                                    index +
                                                    ".dateRange",
                                                  rules:
                                                    _vm.rules.projectRule
                                                      .dateRange,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-picker-pannel",
                                                  },
                                                  [
                                                    _c("font-icon", {
                                                      attrs: {
                                                        href: "#icon-calendar",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "date-picker-wrap",
                                                      },
                                                      [
                                                        _c("el-date-picker", {
                                                          attrs: {
                                                            type: "month",
                                                            placeholder:
                                                              "开始时间",
                                                            "picker-options":
                                                              _vm.setExperienceStartDateOption(
                                                                experience
                                                              ),
                                                          },
                                                          on: {
                                                            change: (picker) =>
                                                              _vm.dateBlur(
                                                                picker,
                                                                experience,
                                                                "start"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              experience.startDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                experience,
                                                                "startDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "experience.startDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date-picker-split",
                                                      },
                                                      [_vm._v("~")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "date-picker-wrap",
                                                      },
                                                      [
                                                        experience.endDate &&
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") > -1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "date-tonow",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        至今\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        experience.endDate &&
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") > -1
                                                          ? _c(
                                                              "el-date-picker",
                                                              {
                                                                staticClass:
                                                                  "opacity-date-picker",
                                                                attrs: {
                                                                  type: "month",
                                                                  placeholder:
                                                                    "结束时间",
                                                                  "picker-options":
                                                                    _vm.setExperienceEndDateOption(
                                                                      experience
                                                                    ),
                                                                },
                                                                on: {
                                                                  change: (
                                                                    picker
                                                                  ) =>
                                                                    _vm.dateBlur(
                                                                      picker,
                                                                      experience,
                                                                      "end"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    experience.endDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        experience,
                                                                        "endDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "experience.endDate",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        !experience.endDate ||
                                                        experience.endDate
                                                          .toString()
                                                          .indexOf("9999") ===
                                                          -1
                                                          ? _c(
                                                              "el-date-picker",
                                                              {
                                                                attrs: {
                                                                  type: "month",
                                                                  placeholder:
                                                                    "结束时间",
                                                                  "picker-options":
                                                                    _vm.setExperienceEndDateOption(
                                                                      experience
                                                                    ),
                                                                },
                                                                on: {
                                                                  change: (
                                                                    picker
                                                                  ) =>
                                                                    _vm.dateBlur(
                                                                      picker,
                                                                      experience,
                                                                      "end"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    experience.endDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        experience,
                                                                        "endDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "experience.endDate",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "start-date-wrap",
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "项目角色",
                                                  prop:
                                                    "projectExperiences." +
                                                    index +
                                                    ".projectRole",
                                                  rules:
                                                    _vm.rules.projectRule
                                                      .projectRole,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "input-item",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      experience.projectRole,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        experience,
                                                        "projectRole",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "experience.projectRole",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "describe",
                                            attrs: { span: 24 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "项目职责",
                                                  prop:
                                                    "projectExperiences." +
                                                    index +
                                                    ".responsibility",
                                                  rules:
                                                    _vm.rules.projectRule
                                                      .responsibility,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "textarea",
                                                    rows: 5,
                                                    placeholder: "请输入内容",
                                                    maxlength: "2000",
                                                    "show-word-limit": "",
                                                  },
                                                  model: {
                                                    value:
                                                      experience.responsibility,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        experience,
                                                        "responsibility",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "experience.responsibility",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "describe description",
                                            attrs: { span: 24 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "项目描述",
                                                  prop:
                                                    "projectExperiences." +
                                                    index +
                                                    ".description",
                                                  rules:
                                                    _vm.rules.projectRule
                                                      .description,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "textarea",
                                                    rows: 5,
                                                    placeholder: "请输入内容",
                                                    maxlength: "8000",
                                                    "show-word-limit": "",
                                                  },
                                                  model: {
                                                    value:
                                                      experience.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        experience,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "experience.description",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "delete-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelProject(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-ic_rubbish" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.showStatement ||
                    (_vm.resumeData.selfEvaluation !== undefined &&
                      !!_vm.resumeData.selfEvaluation)
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-statement",
                            attrs: { id: "contentStatement" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("自我评价"),
                              ]),
                              _c("span", { staticClass: "line" }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "content-inner" },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "first-row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "describe",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "selfEvaluation" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                type: "textarea",
                                                rows: 5,
                                                placeholder: "请输入内容",
                                                maxlength: "4000",
                                                "show-word-limit": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.resumeData.selfEvaluation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.resumeData,
                                                    "selfEvaluation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "resumeData.selfEvaluation",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "delete-btn",
                                on: { click: _vm.handleDelStatement },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-ic_rubbish" },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.resumeData.intentions.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-intention",
                            attrs: { id: "contentIntention" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("期望工作"),
                              ]),
                              _c("span", { staticClass: "line" }),
                              _c(
                                "span",
                                {
                                  staticClass: "add-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addIntention()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-add",
                                  }),
                                  _vm._v("添加"),
                                ]
                              ),
                            ]),
                            _vm._l(
                              _vm.resumeData.intentions,
                              function (intention, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "content-inner",
                                    attrs: { id: "intention_" + index },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "first-row",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "期望职位",
                                                  prop:
                                                    "intentions." +
                                                    index +
                                                    ".title",
                                                  rules:
                                                    _vm.rules.intention.title,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "input-item",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: intention.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        intention,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "intention.title",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "期望城市",
                                                  prop:
                                                    "intentions." +
                                                    index +
                                                    ".locationArr",
                                                  rules:
                                                    _vm.rules.intention
                                                      .locationArr,
                                                  "label-width": "130px",
                                                },
                                              },
                                              [
                                                _c("el-cascader", {
                                                  attrs: {
                                                    props: {
                                                      expandTrigger: "hover",
                                                    },
                                                    options: _vm.cities,
                                                    "show-all-levels": false,
                                                    clearable: true,
                                                  },
                                                  model: {
                                                    value:
                                                      intention.locationArr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        intention,
                                                        "locationArr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "intention.locationArr",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "salary-item" },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "min-salary",
                                                  attrs: {
                                                    label: "薪资要求",
                                                    prop:
                                                      "intentions." +
                                                      index +
                                                      ".minSalary",
                                                    "label-width": "130px",
                                                  },
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    staticClass:
                                                      "min-salary-input",
                                                    attrs: {
                                                      controls: false,
                                                      maxlength: "3",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeSalary(
                                                          intention
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        intention.minSalary,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          intention,
                                                          "minSalary",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "intention.minSalary",
                                                    },
                                                  }),
                                                  _c("div", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: intention.error,
                                                        expression:
                                                          "intention.error",
                                                      },
                                                    ],
                                                    staticClass: "salary-error",
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "divide-line" },
                                                [_vm._v("—")]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "max-salary",
                                                  attrs: {
                                                    prop:
                                                      "intentions." +
                                                      index +
                                                      ".maxSalary",
                                                  },
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    staticClass:
                                                      "max-salary-input",
                                                    attrs: {
                                                      controls: false,
                                                      maxlength: "3",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeSalary(
                                                          intention
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        intention.maxSalary,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          intention,
                                                          "maxSalary",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "intention.maxSalary",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "delete-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelIntention(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-ic_rubbish" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    !_vm.isPersonalCreate
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-newfirsttier",
                            attrs: { id: "contentNewFirstTier" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("新一线机会"),
                              ]),
                              _c("span", { staticClass: "line" }),
                            ]),
                            _c("p", { staticClass: "new-first-tier-tip" }, [
                              _vm._v(
                                "人选是否考虑新一线城市的工作机会？（如：长沙，武汉，成都等）"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "content-inner" },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "thinking-time-item" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "是否及考虑多久",
                                                "label-width": "130px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeThinkingTime,
                                                  },
                                                  model: {
                                                    value: _vm.thinkingTime,
                                                    callback: function ($$v) {
                                                      _vm.thinkingTime = $$v
                                                    },
                                                    expression: "thinkingTime",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.timeOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.showWriteTip
                                            ? _c(
                                                "div",
                                                { staticClass: "write-tip" },
                                                [
                                                  _c("div", {
                                                    staticClass: "triangle",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      "亲，快来填写吧！ 首次填写，加10积分"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "i",
                                                    {
                                                      on: {
                                                        click: _vm.closeTip,
                                                      },
                                                    },
                                                    [_vm._v("×")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isCitySelectShow,
                                              expression: "isCitySelectShow",
                                            },
                                          ],
                                          staticClass: "thinking-city-item",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "考虑城市",
                                                "label-width": "130px",
                                              },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "city-list" },
                                                _vm._l(
                                                  _vm.cityList,
                                                  function (city) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: city.value,
                                                        class: {
                                                          selected:
                                                            _vm.checkCityIfSelected(
                                                              city.value
                                                            ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectNewFirstCity(
                                                              city
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(city.label)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _c("el-input", {
                                                staticClass: "other-city-input",
                                                attrs: {
                                                  placeholder:
                                                    "其他城市（多个城市以空格隔开，限25个字）",
                                                },
                                                on: {
                                                  input: _vm.checkOtherCity,
                                                },
                                                model: {
                                                  value: _vm.otherCity,
                                                  callback: function ($$v) {
                                                    _vm.otherCity = $$v
                                                  },
                                                  expression: "otherCity",
                                                },
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "other-city-input-error",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "prompt-message" },
                                        [
                                          _vm._v(
                                            "提示：首次填写，选择了是否考虑就加积分，其他为选填项，请尽量详细填写"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showSystem && !_vm.isEdit
                      ? _c(
                          "div",
                          {
                            staticClass: "content-block content-system",
                            attrs: { id: "contentSystem" },
                          },
                          [
                            _c("div", { staticClass: "content-title" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("系统记录"),
                              ]),
                              _c("span", { staticClass: "line" }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "content-inner" },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "first-row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass:
                                          "describe inquiry-describe",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "extraInfo",
                                              label: "寻访记录",
                                              "label-width": "130px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                type: "textarea",
                                                rows: 5,
                                                placeholder: "请输入内容",
                                                maxlength: "800",
                                                "show-word-limit": "",
                                              },
                                              on: {
                                                input: _vm.changeExtraInfo,
                                              },
                                              model: {
                                                value: _vm.resumeData.extraInfo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.resumeData,
                                                    "extraInfo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "resumeData.extraInfo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showExtraInfo,
                                        expression: "showExtraInfo",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "jobHoppingOccasion",
                                              "label-width": "130px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "occasion-item" },
                                              [_vm._v("跳槽机会")]
                                            ),
                                            _c(
                                              "el-radio-group",
                                              {
                                                attrs: { size: "mini" },
                                                model: {
                                                  value:
                                                    _vm.resumeData
                                                      .jobHoppingOccasion,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resumeData,
                                                      "jobHoppingOccasion",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resumeData.jobHoppingOccasion",
                                                },
                                              },
                                              [
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "不考虑机会",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "6个月内不考虑",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "12个月内不考虑",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "看职业机会",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: { label: "离职状态" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "刚入职新公司",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label: "已开始创业",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "chance",
                                                  attrs: {
                                                    label:
                                                      "该公司最近有裁员传闻/新闻",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showExtraInfo,
                                        expression: "showExtraInfo",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "employerInfo",
                                              "label-width": "130px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "portrait-item" },
                                              [_vm._v("雇主画像")]
                                            ),
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.resumeData.employerInfo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resumeData,
                                                      "employerInfo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resumeData.employerInfo",
                                                },
                                              },
                                              [
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: {
                                                    label: "知名成熟公司",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: {
                                                    label:
                                                      "创业早期（天使/A/B/C轮）",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: {
                                                    label:
                                                      "创业中后期（D/E/F轮）",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: { label: "上市公司" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: {
                                                    label: "独角兽/行业巨头",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: {
                                                    label: "外资企业/跨国企业",
                                                  },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "portrait",
                                                  attrs: { label: "都可以" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showExtraInfo,
                                        expression: "showExtraInfo",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "expectSalary",
                                              "label-width": "130px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "salary-item" },
                                              [_vm._v("期望薪资")]
                                            ),
                                            _c(
                                              "el-radio-group",
                                              {
                                                attrs: {
                                                  disabled: _vm.isInputSalary,
                                                },
                                                model: {
                                                  value:
                                                    _vm.resumeData.expectSalary,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.resumeData,
                                                      "expectSalary",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "resumeData.expectSalary",
                                                },
                                              },
                                              [
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "20-30W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "30-50W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "50-80W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "80-100W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "100-150W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "150-200W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "200-300W" },
                                                }),
                                                _c("el-radio-button", {
                                                  staticClass: "salary",
                                                  attrs: { label: "300W以上" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm.showDiy
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "diy-salary",
                                                    on: {
                                                      click:
                                                        _vm.showSalaryInput,
                                                    },
                                                  },
                                                  [_vm._v("自定义")]
                                                )
                                              : _vm._e(),
                                            !_vm.showDiy
                                              ? _c(
                                                  "el-input",
                                                  {
                                                    staticClass: "input-salary",
                                                    attrs: {
                                                      size: "mini",
                                                      placeholder: "请输入数字",
                                                      autofocus: true,
                                                      maxlength: "4",
                                                    },
                                                    on: {
                                                      blur: _vm.showSalaryButton,
                                                    },
                                                    model: {
                                                      value: _vm.inputSalary,
                                                      callback: function ($$v) {
                                                        _vm.inputSalary = $$v
                                                      },
                                                      expression: "inputSalary",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("W")]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "content-block content-submit" },
                          [
                            _c(
                              "div",
                              { staticClass: "content-inner" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "submit-cancel",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "el-button",
                                              { on: { click: _vm.cancel } },
                                              [_vm._v("取消")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.validBeforeSubmit(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("提交")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "content-right" }, [
            _c(
              "div",
              {
                staticClass: "menu-panel",
                class: { "fixed-menu": _vm.fixMenu },
              },
              [
                _c("ul", { staticClass: "menu-resume" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.actives.base },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu("base")
                        },
                      },
                    },
                    [
                      _c("font-icon", { attrs: { href: "#icon-ic_person" } }),
                      _c("span", { staticClass: "menu-text" }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm.resumeData.workExperiences.length > 0
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.experience },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("experience")
                            },
                          },
                        },
                        [
                          _c("font-icon", { attrs: { href: "#icon-ic_ex" } }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("工作经历"),
                          ]),
                          _vm.isPersonalCreate
                            ? _c("i", {
                                staticClass: "icon-font icon-quxiao",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelAll("experience")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.workExperiences.length === 0
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.experience },
                          on: { click: _vm.addExperience },
                        },
                        [
                          _c("font-icon", { attrs: { href: "#icon-ic_ex" } }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("工作经历"),
                          ]),
                          _vm.isPersonalCreate
                            ? _c("i", { staticClass: "icon-font icon-add" })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.educationExperiences.length > 0
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.education },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("education")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_xueli" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("教育经历"),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAll("education")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.educationExperiences.length === 0
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.education },
                          on: {
                            click: function ($event) {
                              return _vm.addEducation()
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_xueli" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("教育经历"),
                          ]),
                          _c("i", { staticClass: "icon-font icon-add" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.projectExperiences.length > 0
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.project },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("project")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_project" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("项目经验"),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAll("project")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.projectExperiences.length === 0
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.project },
                          on: {
                            click: function ($event) {
                              return _vm.addProject()
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_project" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("项目经验"),
                          ]),
                          _c("i", { staticClass: "icon-font icon-add" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showStatement ||
                  (_vm.resumeData.selfEvaluation !== undefined &&
                    !!_vm.resumeData.selfEvaluation)
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.statement },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("statement")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_evaluation" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("自我评价"),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAll("statement")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.showStatement && !_vm.resumeData.selfEvaluation
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.statement },
                          on: {
                            click: function ($event) {
                              return _vm.addStatement()
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_evaluation" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("自我评价"),
                          ]),
                          _c("i", { staticClass: "icon-font icon-add" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.intentions.length > 0
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.expect },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("expect")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_zhiwei" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("期望工作"),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAll("intention")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resumeData.intentions.length === 0
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.expect },
                          on: {
                            click: function ($event) {
                              return _vm.addIntention()
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_zhiwei" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("期望工作"),
                          ]),
                          _c("i", { staticClass: "icon-font icon-add" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPersonalCreate
                    ? _c(
                        "li",
                        {
                          class: { active: _vm.actives.newFirstTier },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("newFirstTier")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-xinyixianjihui" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("新一线机会"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showSystem && !_vm.isEdit
                    ? _c(
                        "li",
                        {
                          staticClass: "abled",
                          class: { active: _vm.actives.system },
                          on: {
                            click: function ($event) {
                              return _vm.selectMenu("system")
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_record" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("系统记录"),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAll("system")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.showSystem && !_vm.isEdit
                    ? _c(
                        "li",
                        {
                          staticClass: "disabled",
                          class: { active: _vm.actives.system },
                          on: {
                            click: function ($event) {
                              return _vm.addSystem()
                            },
                          },
                        },
                        [
                          _c("font-icon", {
                            attrs: { href: "#icon-ic_record" },
                          }),
                          _c("span", { staticClass: "menu-text" }, [
                            _vm._v("系统记录"),
                          ]),
                          _c("i", { staticClass: "icon-font icon-add" }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pasteLoading,
              expression: "pasteLoading",
            },
          ],
          staticClass: "paste-dialog",
          attrs: {
            visible: _vm.dialogPasteVisible,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "lock-scroll": false,
            "before-close": _vm.handleClosePaste,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPasteVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "paste-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("i", { staticClass: "icon-font icon-paste" }),
              _vm._v("粘贴简历"),
            ]
          ),
          _c("el-input", {
            attrs: {
              size: "mini",
              type: "textarea",
              rows: 20,
              resize: "none",
              placeholder: "在此粘贴简历文本",
              "show-word-limit": "",
              "close-on-click-modal": false,
            },
            model: {
              value: _vm.pasteContent,
              callback: function ($$v) {
                _vm.pasteContent = $$v
              },
              expression: "pasteContent",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "analysis-btn",
                  on: { click: _vm.analysisResume },
                },
                [_vm._v("解析填充")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "multiple-upload-dialog",
          attrs: {
            visible: _vm.dialogUploadVisible,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUploadVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "result-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("解析结果")]
          ),
          _c("p", [
            _vm._v("简历成功添加"),
            _c("span", { staticClass: "success-style" }, [
              _vm._v(_vm._s(_vm.successedCount)),
            ]),
            _vm._v("份，失败"),
            _c("span", { staticClass: "success-style" }, [
              _vm._v(_vm._s(_vm.failedCount)),
            ]),
            _vm._v("份，重复"),
            _c("span", { staticClass: "success-style" }, [
              _vm._v(_vm._s(_vm.existedCount)),
            ]),
            _vm._v("份。"),
            _vm.existedCount > 0
              ? _c("span", [_vm._v("您可以对重复简历进行覆盖上传，请确认。")])
              : _vm._e(),
          ]),
          _c(
            "ul",
            { staticClass: "failed-list" },
            _vm._l(_vm.failedFiles, function (file, index) {
              return _c("li", { key: index, staticClass: "failed-item" }, [
                _c(
                  "span",
                  { staticClass: "item-name", attrs: { title: file.fileName } },
                  [_vm._v(_vm._s(file.fileName))]
                ),
                _c("span", { staticClass: "item-reason" }, [
                  _vm._v(_vm._s(file.reason)),
                ]),
                file.shouldComplete
                  ? _c(
                      "span",
                      {
                        staticClass: "complete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.completeResume(file)
                          },
                        },
                      },
                      [_vm._v("去补充")]
                    )
                  : _vm._e(),
                file.isExisted
                  ? _c(
                      "span",
                      {
                        staticClass: "see-btn",
                        on: {
                          click: function ($event) {
                            return _vm.compareResume({
                              fileName: file.fileName,
                              candidateId: file.candidate.resumeId,
                              candidate: file.candidate,
                              type: "dialog",
                            })
                          },
                        },
                      },
                      [_vm._v("去对比")]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "result-btn",
                  on: { click: _vm.closeUploadModal },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "excel-import-dialog",
          attrs: {
            visible: _vm.dialogImportVisible,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImportVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "import-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("提示")]
          ),
          _c("p", [
            _vm._v("简历成功解析"),
            _c("span", { staticClass: "success-style" }, [
              _vm._v(_vm._s(_vm.importSuccessedCount)),
            ]),
            _vm._v("份，失败"),
            _c("span", { staticClass: "fail-style" }, [
              _vm._v(_vm._s(_vm.importFailedCount)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "import-btn",
                  on: { click: _vm.closeImportModal },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "show-waiting-dialog",
          attrs: {
            visible: _vm.showPageWaiting,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "lock-scroll": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPageWaiting = $event
            },
          },
        },
        [_c("p", [_vm._v("批量上传简历需要较长时间，请耐心等待: )")])]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "has-no-title",
          attrs: {
            title: "",
            visible: _vm.delDialogVisible,
            width: "480px",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.delDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定删除本段" + _vm._s(_vm.dialogText) + "？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.delDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.delCallback } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "has-no-title",
          attrs: {
            title: "",
            visible: _vm.delAllDialogVisible,
            width: "480px",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.delAllDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定删除" + _vm._s(_vm.dialogText) + "？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.delAllDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.delAllCallback },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.firmLoading,
              expression: "firmLoading",
            },
          ],
          staticClass: "to-firm-dialog",
          attrs: {
            title: "提示",
            visible: _vm.toFirmDialogVisible,
            width: "480px",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.toFirmDialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("h3", [_vm._v("上传个人库成功。")]),
            _c("span", [
              _vm._v(
                "企业库还没有这份简历，现在分享至企业库，可以获得这份简历的归属权哦～"
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelToFirm } }, [
                _vm._v("不了，谢谢"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "firm-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleToFirm },
                },
                [_vm._v("分享到企业库")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "score-feedback-dialog",
          attrs: {
            title:
              _vm.scoreFeedbackType == 1 ? "请输入真实有效的电话号码" : "提示",
            visible: _vm.scoreFeedbackDialogVisible,
            width: "480px",
            "show-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scoreFeedbackDialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm.scoreFeedbackType == 1
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("请输入真实有效的电话号码，剩余"),
                  _c("i", [_vm._v(_vm._s(_vm.scoreFeedbackLimit))]),
                  _vm._v("次填写机会。"),
                ])
              : _vm._e(),
            _vm.scoreFeedbackType == 2
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("电话号码输入错误已超过"),
                  _c("i", [_vm._v("5")]),
                  _vm._v("次，今日无法再对本简历进行更新。"),
                ])
              : _vm._e(),
            _vm.scoreFeedbackType == 3
              ? _c("span", { staticClass: "tip" }, [
                  _vm._v("今日无法再对本简历进行更新。"),
                ])
              : _vm._e(),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "score-feedback-btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.scoreFeedbackDialogVisible = false
                    },
                  },
                },
                [_vm._v("知道了")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("import-result-dialog", {
        ref: "importResultDialog",
        attrs: { isTextContext: true },
        on: {
          "import-confirm": _vm.handleFirmConfirm,
          "import-then-compare": _vm.goToCompare,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }