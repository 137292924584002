<template>
    <div class="page-content single-resume-upload">
        <div class="upload-btn-wrap" :style="isEdit ? 'left: 204px;' : ''">
            <form class="file-upload" ref="upload">
                <label>
                    <input
                        type="file"
                        class="upload-input"
                        id="uploadFile"
                        @change="uploadFile($event)"
                        accept="application/msword,text/html,application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <el-button
                        type="primary"
                        class="upload-file-btn"
                        size="mini"
                    >
                        上传简历附件
                    </el-button>
                </label>
            </form>
        </div>
        <el-row class="content-main">
            <div class="content-wrap">
                <div class="content-left">
                    <el-form :model="resumeData" :rules="rules" ref="resumeForm" label-width="130px" size="mini">
                        <div class="resume-content">
                            <div id="contentBase" class="content-block content-basic">
                                <div class="content-title">
                                    <span class="title">基本信息</span>
                                    <span class="line"></span>
                                    <span class="tips" v-if="resumeData.isExisted">
                                        <a class="tips-link"
                                            @click="compareResume({
                                                fileName:  fileName ? fileName : 'singleEditFile',
                                                candidateId:resumeData.existedId,
                                                candidate: resumeData,
                                                errorInfo: resumeData.errorInfo
                                            })"
                                            href="javascript:;;">该候选人已存在于人才库。去对比 >></a>
                                    </span>
                                </div>
                                <div class="content-inner">
                                    <el-row class="first-row" :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item prop="realName" label="姓名">
                                                <el-input  size="mini" class="input-item" v-model="resumeData.realName"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item id="input-gender" class="recommend-required" prop="gender" label="性别">
                                                <span class="required-mark-wrap" slot="label">
                                                    <el-tooltip class="item" effect="light" content="推荐时必填" placement="top">
                                                        <i class="required-mark">$</i>
                                                    </el-tooltip>
                                                    性别
                                                </span>
                                                <el-button-group v-model="resumeData.gender" size="mini">
                                                    <el-button class="gender-select-btn" :class="{'active': resumeData.gender==1}" @click.native="changeGender(1)">男</el-button>
                                                    <el-button class="gender-select-btn" :class="{'active': resumeData.gender==2}" @click.native="changeGender(2)">女</el-button>
                                                </el-button-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col v-if="(!isEdit || resumeData.canViewContract || resumeData.mobileStatus==3) && !isLocked" :span="12">
                                            <el-form-item id="input-mobile" prop="mobile" label="手机">
                                                <el-input size="mini" class="input-item" v-model="resumeData.mobile" placeholder="国际手机以+或者00开头" @input.native="regexMobile"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col v-if="(!isEdit || resumeData.canViewContract || resumeData.emailStatus==3) && !isLocked" :span="12">
                                            <el-form-item id="input-email" prop="email" label="邮箱">
                                                <el-input size="mini" class="input-item" v-model.trim="resumeData.email"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col v-if="(!isEdit || resumeData.canViewContract || resumeData.wechatStatus==3) && !isLocked" :span="12">
                                            <el-form-item id="input-wechat" prop="wechat" label="微信">
                                                <el-input size="mini" class="input-item" v-model.trim="resumeData.wechat"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item id="input-yearOfExperience" prop="yearOfExperience" label="工作经验" class="yexp-form-item">
                                                <el-input v-model="resumeData.yearOfExperience">
                                                    <template slot="append">年</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item prop="age" class="yexp-form-item recommend-required" label="年龄">
                                                <span class="required-mark-wrap" slot="label">
                                                    <el-tooltip class="item" effect="light" content="推荐时必填" placement="top">
                                                        <i class="required-mark">$</i>
                                                    </el-tooltip>
                                                    年龄
                                                </span>
                                                <el-input v-model="resumeData.age">
                                                    <template slot="append">岁</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item :prop="isPersonalCreate?null:'city'" label="城市">
                                                <el-cascader :props="{expandTrigger: 'hover'}" :options="cities" :show-all-levels="false" :clearable="true" v-model="select.selectedCity" @change="selectCity" filterable></el-cascader>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div id="contentExperience" class="content-block content-experience" v-show="resumeData.workExperiences.length > 0">
                                <div class="content-title">
                                    <span class="title">工作经历</span>
                                    <span class="line"></span>
                                    <span class="add-btn" @click="addExperience()"><i class="icon-font icon-add"></i>添加</span>
                                </div>
                                <div :id="'experience_'+ index" class="content-inner" :key="'experience_'+index" 
                                    v-for="(experience, index) in resumeData.workExperiences" >
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item class="latest-experience">
                                                <el-checkbox v-model="experience.isLatestWorkExperience" @change="choseLatest(index, $event)">设为最近工作经历</el-checkbox>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item :prop="'workExperiences.' + index + '.company'" label="公司" :rules="rules.experienceRule.company">
                                                <el-input  size="mini" class="input-item" v-model="experience.company" v-if="isPersonalCreate"></el-input>
                                                <customer-select-or-write
                                                    size="mini"
                                                    v-if="(experience.company|| experience.company== '' || experience.isAdd || !candidateId) && !isPersonalCreate"
                                                    :customerId.sync="experience.customerid"
                                                    :customerName.sync="experience.company"
                                                    @finish-callback="finishCallback(index)"
                                                    />
                                            </el-form-item>
                                            <!-- :customerId.sync="resumeData.customerId" -->
                                        </el-col>
                                        <!-- {{showDep(experience, resumeData.customerId).toString()}} -->
                                        <el-col :span="12">
                                            <el-form-item 
                                                :prop="'workExperiences.' + index + '.customerDepartmentName'" 
                                                label="部门" 
                                                :rules="rules.experienceRule.customerDepartmentName" 
                                                v-if="experience.isLatestWorkExperience && showDep(experience, resumeData.customerId)"
                                                >
                                                <customer-department-select
                                                    ref="departmentSelect"
                                                    class="resumeData-department-select"
                                                    size="mini"
                                                    :customerDepartmentName="resumeData.customerDepartmentName"
                                                    :canSrcoll="false"
                                                    :customerId="resumeData.customerId"
                                                    :departmentId="resumeData.customerDepartmentId"
                                                    placeholder='输入完整路径，多级部门用"，"分隔'
                                                    @update-department="updateDepartment($event, index)"
                                                ></customer-department-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12" class="start-date-wrap">
                                            <el-form-item :prop="'workExperiences.' + index + '.dateRange'" :rules="rules.experienceRule.dateRange" label="时间" :required="isEdit && !isPersonalCreate">
                                                <div class="date-picker-pannel">
                                                    <font-icon href="#icon-calendar"></font-icon>
                                                    <div class="date-picker-wrap">
                                                        <el-date-picker
                                                            v-model="experience.startDate"
                                                            type="month"
                                                            placeholder="开始时间"
                                                            :picker-options="setExperienceStartDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'start')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                    <span class="date-picker-split">~</span>
                                                    <div class="date-picker-wrap">
                                                        <div class="date-tonow"
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1">
                                                            至今
                                                        </div>
                                                        <el-date-picker
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setExperienceEndDateOption(experience)"
                                                            class="opacity-date-picker"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        ></el-date-picker>
                                                        <el-date-picker
                                                            v-if="!experience.endDate || experience.endDate.toString().indexOf('9999')===-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setExperienceEndDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item :prop="'workExperiences.' + index + '.title'" label="职位" :rules="rules.experienceRule.title">
                                                <el-input  size="mini" class="input-item" v-model="experience.title"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item class="recommend-required" :prop="'workExperiences.' + index + '.location'" label="地区">
                                                <span class="required-mark-wrap" slot="label">
                                                    <el-tooltip class="item" effect="light" content="推荐时必填" placement="top">
                                                        <i class="required-mark">$</i>
                                                    </el-tooltip>
                                                    地区
                                                </span>
                                                <el-cascader :props="{expandTrigger: 'hover'}" :options="cities" :show-all-levels="false" :clearable="true" v-model="experience.locationArr"></el-cascader>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24" class="describe">
                                            <el-form-item class="recommend-required" :prop="'workExperiences.' + index + '.description'" label="内容" :rules="rules.experienceRule.description">
                                                <span class="required-mark-wrap" slot="label">
                                                    <el-tooltip class="item" effect="light" content="推荐时必填" placement="top">
                                                        <i class="required-mark">$</i>
                                                    </el-tooltip>
                                                    内容
                                                </span>
                                                <el-input  size="mini"
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="请输入工作内容"
                                                    maxlength="8000"
                                                    show-word-limit
                                                    v-model="experience.description">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <div class="delete-btn" v-if="isPersonalCreate || (!isPersonalCreate && index > 0)" @click="handleDelExperience(index)">
                                        <font-icon href="#icon-ic_rubbish"></font-icon>
                                    </div>
                                </div>

                            </div>
                            <div id="contentEducation" v-if="resumeData.educationExperiences.length > 0" class="content-block content-education">
                                <div class="content-title">
                                    <span class="title">教育经历</span>
                                    <span class="line"></span>
                                    <span class="add-btn" @click="addEducation()"><i class="icon-font icon-add"></i>添加</span>
                                </div>
                                <div :id="'education_'+ index" class="content-inner" :key="index" v-for="(experience, index) in resumeData.educationExperiences">
                                    <el-row class="first-row" :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item class="university-chose" :prop="'educationExperiences.' + index + '.school'" label="学校" :rules="rules.educationRule.school">
                                                <el-select v-model="experience.school" filterable allow-create @change="(select)=>selectChange(select, experience, 'school')">
                                                    <el-option
                                                        v-for="item in universityList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.name">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="时间" :prop="'educationExperiences.' + index + '.dateRange'" :rules="rules.educationRule.dateRange" :required="isEdit && !isPersonalCreate">
                                                <div class="date-picker-pannel">
                                                    <font-icon href="#icon-calendar"></font-icon>
                                                    <div class="date-picker-wrap">
                                                        <el-date-picker
                                                            v-model="experience.startDate"
                                                            type="month"
                                                            placeholder="开始时间"
                                                            :picker-options="setEducationStartDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'start')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                    <span class="date-picker-split">~</span>
                                                    <div class="date-picker-wrap">
                                                        <div class="date-tonow"
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1">
                                                            至今
                                                        </div>
                                                        <el-date-picker
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setExperienceEndDateOption(experience)"
                                                            class="opacity-date-picker"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        ></el-date-picker>
                                                        <el-date-picker
                                                            v-if="!experience.endDate || experience.endDate.toString().indexOf('9999')===-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setEducationEndDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                </div>
                                            </el-form-item>

                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item class="degree-chose" label="学历" :prop="'educationExperiences.' + index + '.degree'" :rules="rules.educationRule.degree">
                                                <el-select v-model="experience.degree" placeholder="请选择">
                                                    <el-option
                                                        v-for="item in degreeArr"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                        :disabled="item.label == '未知'"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item class="major-chose" label="专业" :prop="'educationExperiences.' + index + '.major'" :rules="rules.educationRule.major" :required="isEdit && !isPersonalCreate">
                                                <el-select v-model="experience.major" filterable allow-create @change="(select)=>selectChange(select, experience, 'major')">
                                                    <el-option
                                                        v-for="item in majorList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.name"
                                                    ></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <div v-if="isPersonalCreate || (!isPersonalCreate && index != 0)" class="delete-btn" @click="handleDelEducation(index)">
                                        <font-icon href="#icon-ic_rubbish"></font-icon>
                                    </div>
                                </div>
                            </div>
                            <div id="contentProject" v-if="resumeData.projectExperiences.length>0" class="content-block content-project">
                                <div class="content-title">
                                    <span class="title">项目经验</span>
                                    <span class="line"></span>
                                    <span class="add-btn" @click="addProject()"><i class="icon-font icon-add"></i>添加</span>
                                </div>
                                <div :id="'project_'+ index" class="content-inner" :key="index" v-for="(experience, index) in resumeData.projectExperiences">
                                    <el-row class="first-row" :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item label="项目名称" :prop="'projectExperiences.' + index + '.projectName'" :rules="rules.projectRule.projectName" label-width="130px">
                                                <el-input  size="mini" class="input-item" v-model="experience.projectName"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12" >
                                            <el-form-item label="时间" :prop="'projectExperiences.' + index + '.dateRange'" :rules="rules.projectRule.dateRange">
                                                <div class="date-picker-pannel">
                                                    <font-icon href="#icon-calendar"></font-icon>
                                                    <div class="date-picker-wrap">
                                                        <el-date-picker
                                                            v-model="experience.startDate"
                                                            type="month"
                                                            placeholder="开始时间"
                                                            :picker-options="setExperienceStartDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'start')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                    <span class="date-picker-split">~</span>
                                                    <div class="date-picker-wrap">
                                                        <div class="date-tonow"
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1">
                                                            至今
                                                        </div>
                                                        <el-date-picker
                                                            v-if="experience.endDate && experience.endDate.toString().indexOf('9999')>-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setExperienceEndDateOption(experience)"
                                                            class="opacity-date-picker"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        ></el-date-picker>
                                                        <el-date-picker
                                                            v-if="!experience.endDate || experience.endDate.toString().indexOf('9999')===-1"
                                                            v-model="experience.endDate"
                                                            type="month"
                                                            placeholder="结束时间"
                                                            :picker-options="setExperienceEndDateOption(experience)"
                                                            @change="(picker) => dateBlur(picker, experience, 'end')"
                                                        >
                                                        </el-date-picker>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12" class="start-date-wrap">
                                            <el-form-item label="项目角色" :prop="'projectExperiences.' + index + '.projectRole'" :rules="rules.projectRule.projectRole" label-width="130px">
                                                <el-input  size="mini" class="input-item" v-model="experience.projectRole"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24" class="describe">
                                            <el-form-item label="项目职责" :prop="'projectExperiences.' + index + '.responsibility'" :rules="rules.projectRule.responsibility" label-width="130px">
                                                <el-input  size="mini"
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="请输入内容"
                                                    maxlength="2000"
                                                    show-word-limit
                                                    v-model="experience.responsibility">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24" class="describe description">
                                            <el-form-item label="项目描述" :prop="'projectExperiences.' + index + '.description'" :rules="rules.projectRule.description" label-width="130px">
                                                <el-input  size="mini"
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="请输入内容"
                                                    maxlength="8000"
                                                    show-word-limit
                                                    v-model="experience.description">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <div class="delete-btn" @click="handleDelProject(index)">
                                        <font-icon href="#icon-ic_rubbish"></font-icon>
                                    </div>
                                </div>
                            </div>
                            <div id="contentStatement" v-if="showStatement || (resumeData.selfEvaluation!==undefined&&!!resumeData.selfEvaluation)" class="content-block content-statement">
                                <div class="content-title">
                                    <span class="title">自我评价</span>
                                    <span class="line"></span>
                                </div>
                                <div class="content-inner">
                                    <el-row class="first-row">
                                        <el-col :span="24" class="describe">
                                            <el-form-item prop="selfEvaluation">
                                                <el-input  size="mini"
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="请输入内容"
                                                    maxlength="4000"
                                                    show-word-limit
                                                    v-model="resumeData.selfEvaluation">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="delete-btn" @click="handleDelStatement">
                                    <font-icon href="#icon-ic_rubbish"></font-icon>
                                </div>
                            </div>
                            <div id="contentIntention" v-if="resumeData.intentions.length>0" class="content-block content-intention">
                                <div class="content-title">
                                    <span class="title">期望工作</span>
                                    <span class="line"></span>
                                    <span class="add-btn" @click="addIntention()"><i class="icon-font icon-add"></i>添加</span>
                                </div>
                                <div :id="'intention_'+ index" class="content-inner" :key="index" v-for="(intention, index) in resumeData.intentions">
                                    <el-row class="first-row" :gutter="20">
                                        <el-col :span="12">
                                            <el-form-item label="期望职位" :prop="'intentions.' + index + '.title'" :rules="rules.intention.title" label-width="130px">
                                                <el-input  size="mini" class="input-item" v-model="intention.title"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="期望城市" :prop="'intentions.' + index + '.locationArr'" :rules="rules.intention.locationArr" label-width="130px">
                                                <el-cascader :props="{expandTrigger: 'hover'}" :options="cities" :show-all-levels="false" :clearable="true" v-model="intention.locationArr"></el-cascader>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <div class="salary-item">
                                                <el-form-item label="薪资要求" class="min-salary" :prop="'intentions.' + index + '.minSalary'" label-width="130px">
                                                    <el-input-number class="min-salary-input" :controls="false" v-model="intention.minSalary" maxlength="3" @change="changeSalary(intention)"></el-input-number>
                                                    <div v-show="intention.error" class="salary-error"></div>
                                                </el-form-item>
                                                <span class="divide-line">—</span>
                                                <el-form-item class="max-salary" :prop="'intentions.' + index + '.maxSalary'">
                                                    <el-input-number class="max-salary-input" :controls="false" v-model="intention.maxSalary" maxlength="3" @change="changeSalary(intention)"></el-input-number>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="delete-btn" @click="handleDelIntention(index)">
                                        <font-icon href="#icon-ic_rubbish"></font-icon>
                                    </div>
                                </div>
                            </div>
                            <!-- <div id="contentNewFirstTier" v-if="!isPersonalCreate && showNewFirstTier" class="content-block content-newfirsttier"> -->
                            <div id="contentNewFirstTier" v-if="!isPersonalCreate" class="content-block content-newfirsttier">
                                <div class="content-title">
                                    <span class="title">新一线机会</span>
                                    <span class="line"></span>
                                </div>
                                <p class="new-first-tier-tip">人选是否考虑新一线城市的工作机会？（如：长沙，武汉，成都等）</p>
                                <div class="content-inner">
                                    <el-row :gutter="20">
                                        <el-col :span="24">
                                            <div class="thinking-time-item">
                                                <el-form-item label="是否及考虑多久" label-width="130px">
                                                    <el-select v-model="thinkingTime" 
                                                        placeholder="请选择"
                                                        @change="changeThinkingTime">
                                                        <el-option
                                                            v-for="item in timeOptions"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <div class="write-tip" v-if="showWriteTip">
                                                    <div class="triangle"></div>
                                                    <span>亲，快来填写吧！ 首次填写，加10积分</span>
                                                    <i @click="closeTip">×</i>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="24">
                                            <div class="thinking-city-item" v-show="isCitySelectShow">
                                                <el-form-item label="考虑城市" label-width="130px">
                                                    <ul class="city-list">
                                                        <li :class="{'selected': checkCityIfSelected(city.value)}" v-for="city in cityList" :key="city.value" @click="selectNewFirstCity(city)">{{ city.label }}</li>
                                                    </ul>

                                                    <el-input class="other-city-input" v-model="otherCity" placeholder="其他城市（多个城市以空格隔开，限25个字）" @input="checkOtherCity"></el-input>
                                                    <div class="other-city-input-error"></div>
                                                </el-form-item>
                                            </div>
                                            <p class="prompt-message">提示：首次填写，选择了是否考虑就加积分，其他为选填项，请尽量详细填写</p>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div id="contentSystem" v-if="showSystem&&!isEdit" class="content-block content-system">
                                <div class="content-title">
                                    <span class="title">系统记录</span>
                                    <span class="line"></span>
                                </div>
                                <div class="content-inner">
                                    <el-row class="first-row">
                                        <el-col :span="24" class="describe inquiry-describe">
                                            <el-form-item prop="extraInfo" label="寻访记录" label-width="130px">
                                                <el-input
                                                    size="mini"
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="请输入内容"
                                                    maxlength="800"
                                                    show-word-limit
                                                    v-model="resumeData.extraInfo"
                                                    @input="changeExtraInfo">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row v-show="showExtraInfo">
                                        <el-col :span="24">
                                            <el-form-item prop="jobHoppingOccasion" label-width="130px">
                                                <div class="occasion-item">跳槽机会</div>
                                                <el-radio-group v-model="resumeData.jobHoppingOccasion" size="mini">
                                                    <el-radio-button class="chance" label="不考虑机会"></el-radio-button>
                                                    <el-radio-button class="chance" label="6个月内不考虑"></el-radio-button>
                                                    <el-radio-button class="chance" label="12个月内不考虑"></el-radio-button>
                                                    <el-radio-button class="chance" label="看职业机会"></el-radio-button>
                                                    <el-radio-button class="chance" label="离职状态"></el-radio-button>
                                                    <el-radio-button class="chance" label="刚入职新公司"></el-radio-button>
                                                    <el-radio-button class="chance" label="已开始创业"></el-radio-button>
                                                    <el-radio-button class="chance" label="该公司最近有裁员传闻/新闻"></el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row v-show="showExtraInfo">
                                        <el-col :span="24">
                                            <el-form-item prop="employerInfo" label-width="130px">
                                                <div class="portrait-item">雇主画像</div>
                                                <el-radio-group v-model="resumeData.employerInfo">
                                                    <el-radio-button class="portrait" label="知名成熟公司"></el-radio-button>
                                                    <el-radio-button class="portrait" label="创业早期（天使/A/B/C轮）"></el-radio-button>
                                                    <el-radio-button class="portrait" label="创业中后期（D/E/F轮）"></el-radio-button>
                                                    <el-radio-button class="portrait" label="上市公司"></el-radio-button>
                                                    <el-radio-button class="portrait" label="独角兽/行业巨头"></el-radio-button>
                                                    <el-radio-button class="portrait" label="外资企业/跨国企业"></el-radio-button>
                                                    <el-radio-button class="portrait" label="都可以"></el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row v-show="showExtraInfo">
                                        <el-col :span="24">
                                            <el-form-item prop="expectSalary" label-width="130px">
                                                <div class="salary-item">期望薪资</div>
                                                <el-radio-group v-model="resumeData.expectSalary" :disabled="isInputSalary">
                                                    <el-radio-button class="salary" label="20-30W"></el-radio-button>
                                                    <el-radio-button class="salary" label="30-50W"></el-radio-button>
                                                    <el-radio-button class="salary" label="50-80W"></el-radio-button>
                                                    <el-radio-button class="salary" label="80-100W"></el-radio-button>
                                                    <el-radio-button class="salary" label="100-150W"></el-radio-button>
                                                    <el-radio-button class="salary" label="150-200W"></el-radio-button>
                                                    <el-radio-button class="salary" label="200-300W"></el-radio-button>
                                                    <el-radio-button class="salary" label="300W以上"></el-radio-button>
                                                </el-radio-group>
                                                <el-button v-if="showDiy" class="diy-salary" @click="showSalaryInput">自定义</el-button>
                                                <el-input  size="mini" v-if="!showDiy" class="input-salary" v-model="inputSalary" @blur="showSalaryButton" placeholder="请输入数字" :autofocus="true" maxlength="4">
                                                    <template slot="append">W</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="content-block content-submit" v-if="isEdit">
                                <div class="content-inner">
                                    <el-row>
                                        <el-col :span="24" class="submit-cancel">
                                            <el-form-item>
                                                <el-button @click="cancel">取消</el-button>
                                                <el-button type="primary" @click="validBeforeSubmit(false)">提交</el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="content-right">
                    <div class="menu-panel" v-bind:class="{ 'fixed-menu': fixMenu }">
                        <ul class="menu-resume">
                            <li @click="selectMenu('base')" v-bind:class="{ active: actives.base }">
                                <font-icon href="#icon-ic_person"></font-icon>
                                <span class="menu-text">基本信息</span>
                            </li>
                            <li @click="selectMenu('experience')" v-if="resumeData.workExperiences.length > 0" class="abled" v-bind:class="{ active: actives.experience }">
                                <font-icon href="#icon-ic_ex"></font-icon>
                                <span class="menu-text">工作经历</span>
                                <i v-if="isPersonalCreate" class="icon-font icon-quxiao" @click="handleDelAll('experience')"></i>
                            </li>
                            <li @click="addExperience" v-if="resumeData.workExperiences.length === 0" class="disabled" v-bind:class="{ active: actives.experience }">
                                <font-icon href="#icon-ic_ex"></font-icon>
                                <span class="menu-text">工作经历</span>
                                <i v-if="isPersonalCreate" class="icon-font icon-add"></i>
                            </li>
                            <li v-if="resumeData.educationExperiences.length > 0" class="abled"  @click="selectMenu('education')" v-bind:class="{ active: actives.education }">
                                <font-icon href="#icon-ic_xueli"></font-icon>
                                <span class="menu-text">教育经历</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('education')"></i>
                            </li>
                            <li v-if="resumeData.educationExperiences.length === 0" class="disabled" v-bind:class="{ active: actives.education }" @click="addEducation()">
                                <font-icon href="#icon-ic_xueli"></font-icon>
                                <span class="menu-text">教育经历</span>
                                <i class="icon-font icon-add"></i>
                            </li>
                            <li v-if="resumeData.projectExperiences.length > 0" class="abled" @click="selectMenu('project')" v-bind:class="{ active: actives.project }">
                                <font-icon href="#icon-ic_project"></font-icon>
                                <span class="menu-text">项目经验</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('project')"></i>
                            </li>
                            <li v-if="resumeData.projectExperiences.length === 0" class="disabled" v-bind:class="{ active: actives.project }" @click="addProject()">
                                <font-icon href="#icon-ic_project"></font-icon>
                                <span class="menu-text">项目经验</span>
                                <i class="icon-font icon-add"></i>
                            </li>
                            <li v-if="showStatement || (resumeData.selfEvaluation!==undefined&&!!resumeData.selfEvaluation)" class="abled" @click="selectMenu('statement')" v-bind:class="{ active: actives.statement }">
                                <font-icon href="#icon-ic_evaluation"></font-icon>
                                <span class="menu-text">自我评价</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('statement')"></i>
                            </li>
                            <li v-if="!showStatement && !resumeData.selfEvaluation" class="disabled" v-bind:class="{ active: actives.statement }" @click="addStatement()">
                                <font-icon href="#icon-ic_evaluation"></font-icon>
                                <span class="menu-text">自我评价</span>
                                <i class="icon-font icon-add"></i>
                            </li>
                            <li v-if="resumeData.intentions.length > 0" class="abled" @click="selectMenu('expect')" v-bind:class="{ active: actives.expect }">
                                <font-icon href="#icon-ic_zhiwei"></font-icon>
                                <span class="menu-text">期望工作</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('intention')"></i>
                            </li>
                            <li v-if="resumeData.intentions.length === 0" class="disabled" v-bind:class="{ active: actives.expect }" @click="addIntention()">
                                <font-icon href="#icon-ic_zhiwei"></font-icon>
                                <span class="menu-text">期望工作</span>
                                <i class="icon-font icon-add"></i>
                            </li>
                            <li v-if="!isPersonalCreate" @click="selectMenu('newFirstTier')" v-bind:class="{ active: actives.newFirstTier }">
                                <font-icon href="#icon-xinyixianjihui"></font-icon>
                                <span class="menu-text">新一线机会</span>
                            </li>
                            <!-- <li v-if="!isPersonalCreate && showNewFirstTier" class="abled" @click="selectMenu('newFirstTier')" v-bind:class="{ active: actives.newFirstTier }">
                                <font-icon href="#icon-xinyixianjihui"></font-icon>
                                <span class="menu-text">新一线机会</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('newFirstTier')"></i>
                            </li>
                            <li v-if="!isPersonalCreate && !showNewFirstTier" class="disabled" v-bind:class="{ active: actives.newFirstTier }" @click="addNewFirstTier()">
                                <font-icon href="#icon-xinyixianjihui"></font-icon>
                                <span class="menu-text">新一线机会</span>
                                <i class="icon-font icon-add"></i>
                            </li> -->
                            <li v-if="showSystem&&!isEdit" class="abled" @click="selectMenu('system')" v-bind:class="{ active: actives.system }">
                                <font-icon href="#icon-ic_record"></font-icon>
                                <span class="menu-text">系统记录</span>
                                <i class="icon-font icon-quxiao" @click="handleDelAll('system')"></i>
                            </li>
                            <li v-if="!showSystem&&!isEdit" class="disabled" v-bind:class="{ active: actives.system }" @click="addSystem()">
                                <font-icon href="#icon-ic_record"></font-icon>
                                <span class="menu-text">系统记录</span>
                                <i class="icon-font icon-add"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-row>
        <el-dialog class="paste-dialog" :visible.sync="dialogPasteVisible" :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false" v-loading="pasteLoading" :before-close="handleClosePaste">
            <div class="paste-title" slot="title"><i class="icon-font icon-paste"></i>粘贴简历</div>
            <el-input  size="mini"
                type="textarea"
                :rows="20"
                resize="none"
                placeholder="在此粘贴简历文本"
                show-word-limit
                v-model="pasteContent"
                :close-on-click-modal="false"
                >
            </el-input>
            <div slot="footer" class="dialog-footer">
                <el-button class="analysis-btn" @click="analysisResume">解析填充</el-button>
            </div>
        </el-dialog>
        <el-dialog class="multiple-upload-dialog" :visible.sync="dialogUploadVisible" :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false">
            <div class="result-title" slot="title">解析结果</div>
            <p>简历成功添加<span class="success-style">{{successedCount}}</span>份，失败<span class="success-style">{{failedCount}}</span>份，重复<span class="success-style">{{existedCount}}</span>份。<span v-if="existedCount>0">您可以对重复简历进行覆盖上传，请确认。</span></p>
            <ul class="failed-list">
                <li class="failed-item" :key="index" v-for="(file, index) in failedFiles">
                    <span class="item-name" v-bind:title="file.fileName">{{file.fileName}}</span>
                    <span class="item-reason">{{file.reason}}</span>
                    <span v-if="file.shouldComplete" class="complete-btn" @click="completeResume(file)">去补充</span>
                    <span v-if="file.isExisted" class="see-btn"  @click="compareResume({fileName:file.fileName,candidateId:file.candidate.resumeId,candidate:file.candidate,type:'dialog'})">去对比</span>
                </li>
            </ul>
            <div slot="footer" class="dialog-footer">
                <el-button class="result-btn" @click="closeUploadModal">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog class="excel-import-dialog" :visible.sync="dialogImportVisible" :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false">
            <div class="import-title" slot="title">提示</div>
            <p>简历成功解析<span class="success-style">{{importSuccessedCount}}</span>份，失败<span class="fail-style">{{importFailedCount}}</span></p>
            <div slot="footer" class="dialog-footer">
                <el-button class="import-btn" @click="closeImportModal">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog class="show-waiting-dialog" :visible.sync="showPageWaiting" :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false" :show-close="false">
            <p>批量上传简历需要较长时间，请耐心等待: )</p>
        </el-dialog>

        <!-- 删除弹窗 -->
        <el-dialog
            title=""
            :visible.sync="delDialogVisible"
            width="480px"
            class="has-no-title"
            :show-close="false"
            :close-on-click-modal="false">
            <span>确定删除本段{{dialogText}}？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="delCallback">确定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title=""
            :visible.sync="delAllDialogVisible"
            width="480px"
            class="has-no-title"
            :show-close="false"
            :close-on-click-modal="false"
            >
            <span>确定删除{{dialogText}}？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delAllDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="delAllCallback">确定</el-button>
            </span>
        </el-dialog>

         <el-dialog
            title="提示"
            :visible.sync="toFirmDialogVisible"
            width="480px"
            class="to-firm-dialog"
            :show-close="false"
            :close-on-click-modal="false"
            v-loading="firmLoading">
            <div>
                <h3>上传个人库成功。</h3>
                <span>企业库还没有这份简历，现在分享至企业库，可以获得这份简历的归属权哦～</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancelToFirm">不了，谢谢</el-button>
                <el-button class="firm-btn" type="primary" @click="handleToFirm">分享到企业库</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :title="scoreFeedbackType == 1 ? '请输入真实有效的电话号码' : '提示'"
            :visible.sync="scoreFeedbackDialogVisible"
            width="480px"
            class="score-feedback-dialog"
            :show-close="true"
            :close-on-click-modal="false"
        >
            <div>
                <span v-if="scoreFeedbackType == 1" class="tip">请输入真实有效的电话号码，剩余<i>{{ scoreFeedbackLimit }}</i>次填写机会。</span>
                <span v-if="scoreFeedbackType == 2" class="tip">电话号码输入错误已超过<i>5</i>次，今日无法再对本简历进行更新。</span>
                <span v-if="scoreFeedbackType == 3" class="tip">今日无法再对本简历进行更新。</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="score-feedback-btn" type="primary" @click="scoreFeedbackDialogVisible = false;">知道了</el-button>
            </span>
        </el-dialog>

        <import-result-dialog
            ref="importResultDialog"
            :isTextContext="true"
            @import-confirm="handleFirmConfirm"
            @import-then-compare="goToCompare"
        ></import-result-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import debounce from "lodash/debounce";
import {mapState,mapGetters,mapActions} from 'vuex';
import provinceData from '#/js/config/provinceThreeData.json';
import newFirstCityData from '#/js/config/newFirstCityData.json';
import degreeData from '#/js/config/degreeData.json';
import cityProvinceMapData from '#/js/config/cityProvinceMap.json';
import resumeSingleService from '#/js/service/resumeSingleService.js';
import { validateMobile, validateMobileScore, validateEmail, validateRealName, validateRealNameScore, validDangeroutStr } from '#/js/util/validateResumeField.js';
import { throttle, errorMessage, getUrlParam } from '#/js/util/tools.js';
import { rules } from '#/js/util/rules.js';
import {resumeUpload as resumeUploadUrl} from '@src/js/config/api.json';
import talentPoolService from '@src/js/service/talentPoolService.js';
import ImportResultDialog from '@src/component/common/dialog/importResultDialog.vue';
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';

const server = window.server_singleton;

export default {
  name: "candidateCreate",
  data() {
    return {
        jobStatusSelectModel: "",
        recruiterSelectModel: "",
        showPageWaiting: false,
        candidateId: "",
        tbdResumeId: "",
        // completion: 70,
        inputSalary: '',
        isEdit: false,
        isUpload: false,
        isLocked: false,
        oldMobile: '',
        oldEmail: '',
        fixMenu: false,
        showStatement: false,
        showNewFirstTier: true,
        showSystem: true,
        showDiy: true,
        isInputSalary: false,
        showExtraInfo: false,
        prepareUpload: false,
        files: [],
        select: {
            selectedCity: []
        },
        //delete all ok?
        deleteAllEducation: false,
        deleteAllProject: false,
        deleteSelfEvaluation: false,
        deleteAllIntention: false,
        deleteSystem: false,
        //add source
        showAddSource: false,
        addSourceName: '',
        //upload data
        dialogUploadVisible: false,
        successedCount: null,
        failedCount: null,
        existedCount: null,
        failedFiles: [],
        dialogImportVisible: false,
        importSuccessedCount: null,
        importFailedCount: null,
        importApprovelTrading: false,
        uploadApprovelTrading: false,
        successedCandidateIds: [],
        //paste resume
        pasteContent: '',
        cities: provinceData.province,
        degreeArr: degreeData.degreeArr,
        sourceArr: [],
        universityList: [],
        majorList: [],
        rules: rules({
            validateMobile: this.validMobile,
            validateMobileScore: this.validMobileScore,
            validateEmail: this.validEmail,
            valideteWechat: this.validWechat,
            validateRealName: this.validRealName,
            validateRealNameText: this.validRealNameText,
            validateYearOfExperience: this.validateYearOfExperience,
            validateWorkDateRange: this.validWorkDateRange,
            validateEducationMajor: this.validEducationMajor,
            validateEducationDateRange: this.validEducationDateRange,
            validateProjectDateRange: this.validProjectDateRange,
        }),
        actives: { base: false, experience: false, education: false, project: false, statement: false, expect: false, newFirstTier: false, system: false },
        resumeData: {
            "__RequestVerificationToken": this.$store.state.verificationToken.verificationToken,
            "isExisted":false,
            "existedId":null,
            "resumeId": "",
            "realName": "",
            "gender": 0,
            "age": '',
            "mobile": "",
            "title": "",
            "email": "",
            "source": null,
            "company": "",
            "yearOfExperience": 0,
            "province": "",
            "city": null,
            "content": "",
            "extraInfo": null,
            "jobHoppingOccasion": null,
            "employerInfo": null,
            "expectSalary": null,
            "school": "",
            "major": "",
            "degree": "0",
            "fileId": "",
            "autoApprovelTrading": false,
            "selfEvaluation": '',
            "workExperiences": [
                {
                    "company": null,
                    "title": "",
                    "description": null,
                    "isLatestWorkExperience": true,
                    "location": 0,
                    "startDate": null,
                    "endDate": null,
                    "dateRange": '',
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                    "locationArr": [],
                }
            ],
            "educationExperiences": [
                {
                    "school": "",
                    "major": "",
                    "degree": null,
                    "startDate": null,
                    "endDate": null,
                    "dateRange": '',
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                }
            ],
            "projectExperiences": [],
            "intentions": [],
            "isPersonalCreate":true
        },
        pickerOptions: {
          disabledDate(date) {
             return date.getTime() > Date.now();
          },
          shortcuts: [{
                text: '至今',
                onClick(picker) {
                    let date = new Date('9999-12-31');
                    picker.$emit('pick', date);

                }
          },{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
          }]
        },
        delDialogVisible: false,//删除教育经历弹窗
        delIndex: -1,
        dialogType:'',
        delAllDialogVisible: false,
        pasteLoading: false,
        isPersonalCreate: true,
        toFirmDialogVisible: false,
        resumeId: '',//用于分享到企业库
        firmLoading: false,
        fileName: "",

        currentIndex: 0, // 选择好公司组件的序号

        isCitySelectShow: true,
        newCityExpectationsInfo: {},

        thinkingTime: '',
        timeOptions: [
            {
                value: '1',
                label: '不考虑'
            },
            {
                value: '3',
                label: '现在考虑'
            },
            {
                value: '5',
                label: '半年内'
            },
            {
                value: '7',
                label: '1年内'
            },
            {
                value: '9',
                label: '1-3年'
            },
            {
                value: '11',
                label: '3年以上'
            },
        ],
        showWriteTip: true,
        
        cityList: newFirstCityData,
        selectNewFirstCityList: [],
        otherCity: '',

        originBirthday: '',

        scoreFeedbackType: 1,
        scoreFeedbackLimit: 0,
        scoreFeedbackDialogVisible: false,
        lastInvalidMobile: ''
    };
  },
  verificationToken() {
    return this.$store.state.verificationToken.verificationToken
  },
  beforeMount() {
    this.refreshPage();
  },
  created() {
    this.isPersonalCreate = _getQueryString('type')=='0'? true: false;
    this.isFromRecommendPage = _getQueryString('isRecommend')=='1'? true: false;
  },
  mounted() {
    this.invokeHideLoading();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    ImportResultDialog,
    CustomerDepartmentSelect,
    CustomerSelectOrWrite,
  },
  computed: {
    firmId() {
        return this.$store.state.user.userInfo.firmId;
    },
    ...mapGetters('resumeUpload',{
        loading: 'loading',
        sourceFrom: 'sourceFrom',
        dialogPasteVisible:'dialogPasteVisible'
    }),
    dialogText(){
        switch (this.dialogType) {
            case 'experience':
                return '工作经历'
                break;
            case 'education':
                return '教育经历'
                break;
            case 'project':
                return '项目经验'
                break;
            case 'statement':
                return '自我评价'
                break;
            case 'intention':
                return '期望工作'
                break;
            case 'newFirstTier':
                return '新一线机会'
                break;
            case 'system':
                return '系统记录'
                break;
            default:
                break;
        }
    }

  },
  methods: {
    closeTip() {
        this.showWriteTip = false;
    },
    checkCityIfSelected(value) {
        function isInArr(item) {
            return item.value == value;
        }
        return this.selectNewFirstCityList.some(isInArr);
    },
    selectNewFirstCity(item) {
        let itemIndex = -1;
        this.selectNewFirstCityList.forEach((city, index) => {
            if(city.value == item.value) {
                itemIndex = index;
            }
        })
        if(itemIndex > -1) {
            this.selectNewFirstCityList.splice(itemIndex, 1);
        } else {
            this.selectNewFirstCityList.push(item);
        }
        console.log(this.selectNewFirstCityList);
    },

    updateDepartment(department, idx) {
        this.resumeData.customerDepartmentId = department.id;
        this.resumeData.customerDepartmentName = department.departmentName;
        this.resumeData.workExperiences[+idx]['customerDepartmentId'] = department.id;
    },
    finishCallback (idx) {
        this.currentIndex = idx;
        this.resumeData.customerDepartmentName = '';
        this.resumeData.customerDepartmentId = 0;

        // 经历中保存customerId
        // this.resumeData.workExperiences[+idx]['customerid'] = this.resumeData.customerId || null;
        // this.$forceUpdate();
        if(this.resumeData.workExperiences[+idx].isLatestWorkExperience) {
            // 将公司转为最近一份工作的公司
            this.resumeData.company = this.resumeData.workExperiences[+idx].company;
            this.resumeData.customerId = this.resumeData.workExperiences[+idx].customerid;
        }

        this.$nextTick(() => {
            console.log(this.$refs.departmentSelect)
            this.$refs.departmentSelect && this.$refs.departmentSelect[this.currentIndex] && this.$refs.departmentSelect[this.currentIndex].reset();
        });
    },
    // 显示公司部门
    showDep(experience, resumeDataCustomerId){
        /**
         * 1.全局公司名和经验公司名相同
         *      有customId显示组织
         *      没有customId不显示组织
         * 2.全局公司名为空或者非标准，经验公司为标准公司，也要显示
         */
        if (experience.isLatestWorkExperience && !this.isPersonalCreate) {
            if(this.resumeData.company && experience.company == this.resumeData.company) {
                if((resumeDataCustomerId && resumeDataCustomerId== experience.customerid) || (!resumeDataCustomerId && experience.customerid)) {
                    return true;
                } else {
                    return false;
                }
            }
            if(!this.resumeData.company || !resumeDataCustomerId){
                if(experience.customerid) {
                    return true;
                }else{
                    return false;
                }
            }
            return false;
        } else {
            return false;
        }
    },
    init(){
        window.addEventListener('scroll', this.handleScroll);
        this.getUniversityList();
        this.getMajorList();
        //是否为补充简历
        let file = getUrlParam('file');
        this.candidateId = getUrlParam('candidateId');
        if(file!==''){
            let fileData = sessionStorage.getItem(file) || sessionStorage.getItem(encodeURIComponent(file));
            this.fileName = decodeURI(file);
            this.fillData(JSON.parse(fileData));
        }
        if(!!this.candidateId){
            this.invokeShowLoading();
            this.isEdit = true;
            let firmName = this.$store.state.user.userInfo.firmShortName;
            document.title = `编辑候选人_${firmName}_猎必得_最有效的O2O猎头招聘平台`;
            this.getCandidateStatus();
            //获取简历数据
            if(this.isPersonalCreate){
                _request({
                    method: 'GET',
                    url: `/ResumeDetail/${this.candidateId}`,
                    data:{'_': Number(new Date()), isEdit: true}
                }).then(res => {
                    this.tbdResumeId = res.tbdResumeId || '';
                    this.oldMobile = res.mobile;
                    this.oldEmail = res.email;
                    if(res.email === 'xxx@xx.com'){
                        res.email = '';
                    }
                    this.fillData(res);
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.invokeHideLoading();
                })

            } else {
                _request({
                    method: 'GET',
                    url: `/ResumeDetail/${this.candidateId}`,
                    data:{isIndividual: this.isPersonalCreate, isEdit: true}
                }).then(res => {
                    this.tbdResumeId = res.tbdResumeId || '';
                    this.oldMobile = res.mobile;
                    this.oldEmail = res.email;
                    if(res.email === 'xxx@xx.com'){
                        res.email = '';
                    }
                    this.fillData(res);

                    this.getNewCityExpectations(res.tbdResumeId);
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.invokeHideLoading();
                })
            }
        }
    },
    regexMobile(ev){
        let val = ev.target.value;
        val = val.replace(/\-/g,'');
        this.$set(this.resumeData,'mobile',val);
    },
    getUniversityList(){
        var _self = this;
        resumeSingleService.getUniversityList()
        .then((res)=>{
            this.universityList = res;
        }).catch((err)=>{});
    },
    getMajorList(){
        var _self = this;
        resumeSingleService.getMajorList()
        .then((res)=>{
            this.majorList = res;
        })
    },
    refreshPage(otherParams = {}) {
      this.invokeShowLoading();
    },
    handleScroll: function() {
        var _self = this;
        throttle(() => {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            let top = $('#contentBase').offset().top || 465;
            if(scrollTop >= top){
                _self.fixMenu = true;
            }else{
                _self.fixMenu = false;
            }
        }, 100)
    },
    completeResume(file){
        sessionStorage.setItem(encodeURIComponent(file.fileName), JSON.stringify(file.candidate));
        window.open('Create?file=' + file.fileName);
    },
    compareResume(file){
        if(!file.candidateId && (!file.type || file.type!=='dialog')){
            shortTips(file.errorInfo);
            return;
        }

        let age = file.candidate.age;
        if(age && this.getAgeFromBirthday(this.originBirthday) != age) {
            if(this.originBirthday.indexOf('0001') == 0) {
                let str = moment().format('-MM-DD');
                let year = parseInt(moment().format('YYYY')) - age;
                file.candidate.birthday = year + str + 'T00:00:00';
            }else {
                let str = this.originBirthday.slice(4) || moment().format('-MM-DD');
                let year = parseInt(moment().format('YYYY')) - age;
                file.candidate.birthday = year + str;
            }
        }

        sessionStorage.setItem(encodeURIComponent(file.fileName), JSON.stringify(file.candidate));
        if(file.type && file.type==='dialog'){
            window.open('/Headhunting/MyCompany.html#/candidate/updatecheck?file=' + file.fileName + '&originalResumeId=' + file.candidateId);
            // window.open('/#/candidate/updatecheck?file=' + file.fileName + '&originalResumeId=' + file.candidateId);
        }else{
            location.href = '/Headhunting/MyCompany.html#/candidate/updatecheck?file=' + file.fileName + '&originalResumeId=' + file.candidateId;
            // location.href = '/#/candidate/updatecheck?file=' + file.fileName + '&originalResumeId=' + file.candidateId;
        }
    },
    changeGender(value){
        if(value===1){
            if(this.resumeData.gender===1){
                this.resumeData.gender = 0;
            }else{
                this.resumeData.gender = 1;
            }
        }else if(value===2){
            if(this.resumeData.gender===2){
                this.resumeData.gender = 0;
            }else{
                this.resumeData.gender = 2;
            }
        }
        this.validGender();
    },
    changeSalary(intention){
        setTimeout(function(){
            if(intention.minSalary > intention.maxSalary){
                $('.salary-error').html('最小薪资不能大于最大薪资');
                intention.error = true;
            } else if(intention.minSalary <= 0 || intention.minSalary > 999 || intention.maxSalary <= 0 ||  intention.maxSalary > 999) {
                $('.salary-error').html('薪资范围为0-999');
                intention.error = true;
            }
            else {
                intention.error = false;
            }
        });
    },
    // 添加删除工作经历
    addExperience() {
        let experienceData = {
            "company": "",
            "title": "",
            "description": null,
            "isLatestWorkExperience": false,
            "location": 0,
            "startDate": null,
            "endDate": null,
            "dateRange": '',
            "created": "",
            "updated": "",
            //"showPopover": false,
            "locationArr": [],
            "isAdd": true
        };

        this.resumeData.workExperiences.push(experienceData);
        setTimeout(() => {
            //location.href = '#experience_' + (this.resumeData.workExperiences.length-1);
            this.scrollById('experience_' + (this.resumeData.workExperiences.length-1));
        }, 100);
    },
    removeExperience(index) {
        let deleteLatest = false;
        if(!this.isPersonalCreate&&this.resumeData.workExperiences.length===1){
            shortTips('至少保留一个工作经历');
            return;
        }
        if(this.resumeData.workExperiences[index].isLatestWorkExperience === true){
            deleteLatest = true;
        }
        this.resumeData.workExperiences.splice(index, 1);
        //如果删除最新工作经历，则将第一个作为最新
        if(deleteLatest){
            this.resumeData.workExperiences[0]&&(this.resumeData.workExperiences[0].isLatestWorkExperience = true);
        }
    },
    // 添加删除教育经历
    addEducation() {
        let educationData = {
            "school": "",
            "major": "",
            "degree": null,
            "startDate": null,
            "endDate": null,
            "dateRange": '',
            "created": "",
            "updated": "",
        };
        this.resumeData.educationExperiences.push(educationData);
        setTimeout(() => {
            this.scrollById('education_' + (this.resumeData.educationExperiences.length-1))
        }, 100);
    },
    removeEducation(index) {
        this.resumeData.educationExperiences.splice(index, 1);
        if(this.resumeData.educationExperiences.length===0){
            this.actives.education = false;
        }
    },
    removeAllEducation() {
        this.resumeData.educationExperiences = [];
        this.actives.education = false;
    },
    removeAllExperience(){
        this.resumeData.workExperiences = [];
        this.actives.experience = false;
    },
    // 添加删除项目经验
    addProject() {
        let projectData = {
            "projectName ": "",
            "projectRole ": "",
            "description": null,
            "responsibility": "",
            "startDate": null,
            "endDate": null,
            "dateRange": '',
            "created": "",
            "updated": "",
        };
        this.resumeData.projectExperiences.push(projectData);
        setTimeout(() => {
            this.scrollById('project_' + (this.resumeData.projectExperiences.length-1))
        }, 100);
    },
    removeProject(index) {
        this.resumeData.projectExperiences.splice(index, 1);
        if(this.resumeData.projectExperiences.length===0){
            this.actives.project = false;
        }
    },
    removeAllProject() {
        this.resumeData.projectExperiences = [];
        this.actives.project = false;
    },
    // 添加删除自我评价
    addStatement() {
        this.showStatement = true;
        setTimeout(() => {
            this.scrollById('contentStatement');
        }, 100);
    },
    removeStatement() {
        this.showStatement = false;
        this.resumeData.selfEvaluation = '';
        this.actives.statement = false;
    },
    // 添加删除期望工作
    addIntention() {
        let intentionData = {
            "id": "",
            "title": "",
            "locationId": null,
            "locationArr": [],
            "minSalary": null,
            "maxSalary": null,
            "error": false,
        };
        this.resumeData.intentions.push(intentionData);
        setTimeout(() => {
            this.scrollById('intention_' + (this.resumeData.intentions.length-1))
        }, 100);
    },
    removeIntention(index) {
        this.resumeData.intentions.splice(index, 1);
        if(this.resumeData.intentions.length===0){
            this.actives.expect = false;
        }
    },
    removeAllIntention() {
        this.resumeData.intentions = [];
        this.actives.expect = false;
    },
    // // 添加新一线城市
    // addNewFirstTier() {
    //     this.showNewFirstTier = true;
    //     setTimeout(() => {
    //         this.scrollById('contentNewFirstTier')
    //     }, 100);
    // },
    // 添加删除系统记录
    addSystem() {
        this.showSystem = true;
        setTimeout(() => {
            //location.href = '#contentSystem';
            this.scrollById('contentSystem')
        }, 100);
    },
    // removeNewFirstTier() {
    //     this.showNewFirstTier = false;
    //     this.actives.newFirstTier = false;

    //     this.selectNewFirstCityList = [];
    //     this.otherCity = '';

    //     let error = document.getElementsByClassName("other-city-input-error")[0];
    //     let input = document.getElementsByClassName("other-city-input")[0];
    //     error.innerHTML = "";
    //     input.classList.remove("adress-input-error");
    // },
    removeSystem() {
        this.showSystem = false;
        this.actives.system = false;
    },
    //自定义校验规则
    repeatCallback(r, type) {
        if(!r){
            return;
        }
        if(r.succeeded){
            // this.resumeData.isExisted = false;
            this.$set(this.resumeData,'isExisted', false);
            if(type!=='mobile' && $('#input-mobile').find('.mobile-error').length>0){
                $('#input-mobile').find('.mobile-error').remove();
            }
        }else {
            console.log(r)
            if(r.data.resumeId) {
                this.$set(this.resumeData,'isExisted', true);
                this.$set(this.resumeData,'existedId', r.data.resumeId);
                this.scrollById('contentBase');
                shortTips('该候选人已存在于人才库');
                if(r.data.candidatId && type==='mobile'){
                    // $('#input-mobile').find('.el-form-item__content').append(`
                    //     <div class="mobile-error">
                    //         该人才已存在于人才库
                    //         <a class="to-detail" href="/Headhunting/MyCompany.html#/candidateDetail/${r.data.candidatId}" target="_blank"><i class="el-icon-d-arrow-right"></i>去查看</a>
                    //         <a class="to-detail" id="singleEditCompare" href="javascript:;;"><i class="el-icon-d-arrow-right"></i>去对比</a>
                    //     </div>
                    // `)
                    $('#input-mobile').find('.el-form-item__content').append(`
                        <div class="mobile-error">
                            该人才已存在于人才库
                            <a class="to-detail" href="/#/candidateDetail/${r.data.candidatId}" target="_blank"><i class="el-icon-d-arrow-right"></i>去查看</a>
                            <a class="to-detail" id="singleEditCompare" href="javascript:;;"><i class="el-icon-d-arrow-right"></i>去对比</a>
                        </div>
                    `)
                    this.$nextTick(() => {
                        document.querySelector('#singleEditCompare').addEventListener('click', () => {
                            this.compareResume({
                                fileName: this.fileName ? this.fileName : 'singleEditFile',
                                candidateId: this.resumeData.existedId,
                                candidate: this.resumeData,
                                errorInfo: this.resumeData.errorInfo
                            });
                        }, {once: true});
                    })
                }
            } else if(r.message) {
                shortTips(r.message);
            }
        }
    },
    validRealNameText(rule, value, callback) {
        if (this.isPersonalCreate || getUrlParam('file')!=='') {
            callback();
            return;
        }

        // if (value.indexOf('某') > -1) {
        //     callback(new Error("请输入真实有效的姓名"));
        //     return;
        // }
        // callback();
        // return;

        validateRealNameScore(rule, value, callback, this.candidateId, () => {
            // _self.repeatCallback(r, 'realName');
        });
    },
    validRealName(rule, value, callback) {
        validDangeroutStr(rule, value, callback);

        if(this.isEdit || this.isPersonalCreate || !this.resumeData.mobile  || getUrlParam('file')!==''){
            callback();
            return;
        }
        let _self = this;
        let validateData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        };
        
        validateRealName(rule, value, callback, !this.isUpload, validateData, function(r) {
            _self.repeatCallback(r, 'realName');
        });
    },
    validGender() {
        if(this.isEdit || this.isPersonalCreate || !this.resumeData.realName || !this.resumeData.mobile  || getUrlParam('file')!==''){
            if(getUrlParam('file') === 'singleEditFile' && this.resumeData.gender && $('#input-gender').find('.uncomplete-tip').length > 0) {
                $('#input-gender').find('.uncomplete-tip').remove();
            }
            return;
        }
        let validateData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        };
        resumeSingleService.resumeValidateRepeat(validateData)
        .then((res)=>{
            this.resumeData.isExisted = false;
            if($('#input-mobile').find('.mobile-error').length>0){
                $('#input-mobile').find('.mobile-error').remove();
            }
        })
        .catch((err)=>{
            this.$set(this.resumeData,'isExisted', true);
                this.$set(this.resumeData,'existedId', err.data.resumeId);
                this.scrollById('contentBase')
                shortTips('该候选人已存在于人才库');
        })
    },
    validMobileScore(rule, value, callback) {
        if (value && this.lastInvalidMobile && value == this.lastInvalidMobile) {
            callback(new Error("请输入真实有效的手机号"));
            return;
        }
        if(this.isPersonalCreate || getUrlParam('file')!==''){
            callback();
            return;
        } else {
            validateMobileScore(rule, value, callback, this.resumeId, this.firmId, (type, limit) => {
                this.scoreFeedbackType = type;
                this.scoreFeedbackLimit = limit || 0;
                this.scoreFeedbackDialogVisible = true;
            }, () => {
                this.lastInvalidMobile = value;
            });
        }
        // return resumeSingleService.validMobileScore({
        //     "Mobile": value, 
        //     "__RequestVerificationToken": this.verificationToken
        // }).then(res => {
        //     debugger
        //     callback();
        //     return;
        // }).catch(err => {
        //     debugger
        //     callback(new Error("请输入真实有效的手机号"));
        //     return;
        // })
    },
    validMobile(rule, value, callback) {
        let _self = this;
        let validateData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        };
        if(this.isEdit || this.isPersonalCreate || getUrlParam('file')!==''){
            validateMobile(rule, value, callback, false);
        } else {
            validateMobile(rule, value, callback, !this.isUpload, validateData, function(r){
                _self.repeatCallback(r, 'mobile');
            });
        }
    },
    validEmail(rule, value, callback) {
        let _self = this;
        let validateData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        };
        if(this.isEdit|| this.isPersonalCreate || getUrlParam('file')!==''){
            if(getUrlParam('file') === 'singleEditFile' && this.resumeData.email && $('#input-email').find('.uncomplete-tip').length > 0) {
                $('#input-email').find('.uncomplete-tip').remove();
            }
            validateEmail(rule, value, callback, false);
        }else{
            validateEmail(rule, value, callback, !this.isUpload, validateData, function(r){
                _self.repeatCallback(r, 'email');
            });
        }
    },
    validWechat(rule, value, callback) {
        if(getUrlParam('file') === 'singleEditFile' && value && $('#input-yearOfExperience').find('.uncomplete-tip').length > 0) {
            $('#input-yearOfExperience').find('.uncomplete-tip').remove();
        }
        let reg = /^\S{6,20}$/;
        if(value && !reg.test(value)) {
            callback(new Error("微信的长度限6~20位"));
        } else {
            callback();
        }
    },
    validateYearOfExperience(rule, value, callback) {
        if(getUrlParam('file') === 'singleEditFile' && value && $('#input-yearOfExperience').find('.uncomplete-tip').length > 0) {
            $('#input-yearOfExperience').find('.uncomplete-tip').remove();
        }
        let reg = /^[0-9]\d*$/;
        if(!reg.test(value)) {
            callback(new Error("工作经验必须为整数"));
        } else if(parseInt(value) > 99 || parseInt(value) < 0) {
            callback(new Error("工作经验的范围为0~99"))
        } else {
            callback();
        }
    },
    validEducationMajor(rule, value, callback) {
        if (this.isPersonalCreate) {
            callback();
            return;
        }

        if (this.isEdit) {
            if (value == '') {
                callback(new Error('专业不能为空'));
                return;
            } else {
                callback();
                return;
            }
        } else {
            callback();
            return;
        }
    },
    validWorkDateRange(rule, value, callback) {
        if (this.isPersonalCreate) {
            callback();
            return;
        }
        if (value == '' || value == undefined) {
            if (this.isEdit) {
                callback(new Error('请输入工作经历时间'));
                return;
            } else {
                callback();
                return;
            }
        } else {
            if (value[0] == '' || value[1] == '' || value[0] == '0001-01-01T00:00:00' || value[1] == '0001-01-01T00:00:00') {
                callback(new Error("请输入工作经历时间"));
                return;
            }

            if ((typeof value[1] == 'string' && value[1].indexOf('9999') == 0)
                || (typeof value[1] == 'object' && value[1].getFullYear() == '9999')) {
                if (moment() - moment(value[0]) > 45*365*24*60*60*1000) {
                    callback(new Error("请输入真实的工作经历时间"));
                } else {
                    callback();
                }
            } else {
                if (moment(value[1]) - moment(value[0]) > 45*365*24*60*60*1000) {
                    callback(new Error("请输入真实的工作经历时间"));
                } else {
                    callback();
                }
            }
            return;
        }
    },
    validEducationDateRange(rule, value, callback) {
        if (this.isPersonalCreate) {
            callback();
            return;
        }

        if (value == '' || value == undefined) {
            if (this.isEdit) {
                callback(new Error('请输入教育经历时间'));
                return;
            } else {
                callback();
                return;
            }
        } else {
            if (value[0] == '' || value[1] == '' || value[0] == '0001-01-01T00:00:00' || value[1] == '0001-01-01T00:00:00') {
                callback(new Error("请输入工作教育时间"));
                return;
            }

            if ((typeof value[1] == 'string' && value[1].indexOf('9999') == 0)
                || (typeof value[1] == 'object' && value[1].getFullYear() == '9999')) {
                if (moment() - moment(value[0]) < 365*24*60*60*1000) {
                    callback(new Error("请输入真实的教育经历时间"));
                } else {
                    callback();
                }
            } else {
                if (moment(value[1]) - moment(value[0]) < 365*24*60*60*1000) {
                    callback(new Error("请输入真实的教育经历时间"));
                } else {
                    callback();
                }
            }
            return;
        }
    },
    validProjectDateRange(rule, value, callback) {
        if (value && value.length > 0) {
            if ((typeof value[1] == 'string' && value[1].indexOf('9999') == 0)
                || (typeof value[1] == 'object' && value[1].getFullYear() == '9999')) {
                if (moment() - moment(value[0]) > 45*365*24*60*60*1000) {
                    callback(new Error("请输入真实的项目经历时间"));
                } else {
                    callback();
                }
            } else {
                if (moment(value[1]) - moment(value[0]) > 45*365*24*60*60*1000) {
                    callback(new Error("请输入真实的项目经历时间"));
                } else {
                    callback();
                }
            }
        } else {
            callback();
        }
    },
    //选择最近工作经历
    choseLatest(index, value) {
        if(value){
            for(let i=0;i<this.resumeData.workExperiences.length;i++){
                this.resumeData.workExperiences[i].isLatestWorkExperience = false;
            }
            this.resumeData.workExperiences[index].isLatestWorkExperience = true;
            // 将公司转为最近一份工作的公司
            this.resumeData.company = this.resumeData.workExperiences[index].company;
            this.resumeData.customerId = this.resumeData.workExperiences[index].customerid;
            // this.$forceUpdate();
            this.$nextTick(() => {
                this.$refs.departmentSelect.forEach(depSelect => {
                    depSelect && depSelect.reset();
                })
            });
        }
    },
    //自定义薪资操作
    showSalaryInput() {
        this.showDiy = false;
        this.isInputSalary = true;
        this.resumeData.expectSalary = null;
    },
    showSalaryButton() {
        if(this.inputSalary===''){
            this.showDiy = true;
            this.isInputSalary = false;
        }
    },
    //输入寻访内容变化
    changeExtraInfo() {
        if(this.resumeData.extraInfo===''){
            this.showExtraInfo = false;
            this.resumeData.jobHoppingOccasion = null;
            this.resumeData.employerInfo = null;
            this.resumeData.expectSalary = null;
        }else{
            this.showExtraInfo = true;
        }
    },
    selectChange(select, experience, type){
        if(type === 'school'){
            if(this.universityList.every((item)=>{ return item.name!==select})){
                this.universityList.push({name: select})
            }
        }else if(type === 'major'){
            if(this.majorList.every((item) => { return item.name !== select })) {
                this.majorList.push({name: select})
            }
            if(this.resumeData.educationExperiences[0].major) {
                $(`#education_0`).find('.major-chose').find('.uncomplete-tip').remove();
            }
        }
    },
    setDateNull(date){
        return (date==="0001-01-01T00:00:00") ? null : date;
    },
    //填充简历数据
    fillData(data){
        data.tbdResumeId = data.tbdResumeId || this.tbdResumeId || '';
        this.resumeId = data.tbdResumeId || this.tbdResumeId || '';
        this.resumeData.resumeId = data.tbdResumeId || this.tbdResumeId || '';

        this.$refs['resumeForm'].resetFields();
        let _self = this;
        data.workExperiences = data.workExperiences ? data.workExperiences : [];
        data.educationExperiences = data.educationExperiences ? data.educationExperiences : [];
        data.projectExperiences = data.projectExperiences ? data.projectExperiences : [];
        data.intentions = data.intentions ? data.intentions : [];
        data.email = data.email ? data.email : "";
        data.age = data.birthday ? this.getAgeFromBirthday(data.birthday) : '';
        if(data.workExperiences.length === 0){
            let experienceData = {
                "company": "",
                "title": "",
                "description": null,
                "isLatestWorkExperience": false,
                "location": 0,
                "startDate": null,
                "endDate": null,
                "dateRange": '',
                "created": "",
                "updated": "",
                //"showPopover": false,
                "locationArr": [],
                "customerid": null,
            };
            
            experienceData.company = data.company || "";
            experienceData.title = data.title || "";
            data.workExperiences.push(experienceData);
        }
        data.workExperiences.forEach(item => {
            if (item.startDate || item.endDate) {
                item.dateRange = [item.startDate || '', item.endDate || ''];
            } else {
                item.dateRange = '';
            }
        })
        if((data.school || data.degree !== null) && data.educationExperiences.length === 0) {
            let educationData = {
                "school": "",
                "major": "",
                "degree": null,
                "startDate": null,
                "endDate": null,
                "dateRange": '',
                "created": "",
                "updated": "",
            };
            educationData.school = data.school || "";
            educationData.degree = parseInt(data.degree);
            educationData.major = data.major || "";
            data.educationExperiences.push(educationData);
        }
        data.educationExperiences.forEach(item => {
            if (item.startDate || item.endDate) {
                item.dateRange = [item.startDate || '', item.endDate || ''];
            } else {
                item.dateRange = '';
            }
        })
        data.projectExperiences.forEach(item => {
            if (item.startDate || item.endDate) {
                item.dateRange = [item.startDate || '', item.endDate || ''];
            } else {
                item.dateRange = '';
            }
        })
        if(data.degree) {
            data.degree = parseInt(data.degree);
        }
        _self.resumeData = data;
        _self.originBirthday = data.birthday || '';

        if(_self.resumeData.customerId == '0'){
            _self.resumeData.customerId = null;
        }
        // _self.resumeData.autoApprovelTrading = true;
        _self.resumeData.__RequestVerificationToken = _self.verificationToken;
        let noLatestExperience = _self.resumeData.workExperiences.every( experience => !experience.isLatestWorkExperience);
        for(let i = 0; i < _self.resumeData.workExperiences.length; i++){
            let workExperiencesItem = _self.resumeData.workExperiences[i];
            //this.$set(workExperiencesItem,"showPopover",false);
            if(i===0 && !workExperiencesItem.isLatestWorkExperience && noLatestExperience){
                this.$set(workExperiencesItem,"isLatestWorkExperience",true);
            }else if(i!==0 && !workExperiencesItem.isLatestWorkExperience && noLatestExperience){
                this.$set(workExperiencesItem,"isLatestWorkExperience",false);
            }

            if(workExperiencesItem.isLatestWorkExperience) {
                workExperiencesItem["customerid"] = _self.resumeData.customerId || null;
                workExperiencesItem["customerDepartmentId"] = _self.resumeData.customerDepartmentId || null;
            } else {
                workExperiencesItem["customerid"] = null;
                workExperiencesItem["customerDepartmentId"] = null;
            }

            if(!workExperiencesItem.location){
                this.$set(workExperiencesItem,"locationArr",[]);
            }else{
                 let pId = Number(_self.getProvinceByCity(workExperiencesItem.location).id);
                 this.$set(workExperiencesItem,"locationArr",[pId, workExperiencesItem.location]);
            }
            workExperiencesItem.startDate = _self.setDateNull(workExperiencesItem.startDate);
            workExperiencesItem.endDate = _self.setDateNull(workExperiencesItem.endDate);
        }
        for(let i = 0; i < _self.resumeData.educationExperiences.length; i++){
            let educationExperiencesItem = _self.resumeData.educationExperiences[i];
           // this.$set(educationExperiencesItem,"showPopover",false);
            educationExperiencesItem.startDate = _self.setDateNull(educationExperiencesItem.startDate);
            educationExperiencesItem.endDate = _self.setDateNull(educationExperiencesItem.endDate);
            if(educationExperiencesItem.degree !== null) {
                educationExperiencesItem.degree = parseInt(educationExperiencesItem.degree);
            }
        }
        for(let i = 0; i < _self.resumeData.projectExperiences.length; i++){
            let projectExperiencesItem = _self.resumeData.projectExperiences[i];
            //this.$set(projectExperiencesItem,"showPopover",false);
            projectExperiencesItem.startDate = _self.setDateNull(projectExperiencesItem.startDate);
            projectExperiencesItem.endDate = _self.setDateNull(projectExperiencesItem.endDate);
        }
        for(let i = 0; i < _self.resumeData.intentions.length; i++){
            let intentionItem = _self.resumeData.intentions[i];
            //this.$set(intentionItem,"showPopover",false);
            this.$set(intentionItem,"error",false);
            if(!intentionItem.locationId){
                this.$set(intentionItem,"locationArr",[]);
            }else{
                //城市转换
                 let pId = Number(_self.getProvinceByCity(intentionItem.locationId).id);
                 this.$set(intentionItem,"locationArr",[pId, intentionItem.locationId]);
            }
        }
        if(_self.resumeData.city && !_self.resumeData.province) {
            _self.resumeData.province = parseInt(_self.getProvinceByCity(_self.resumeData.city).id);
        }

        if(_self.resumeData.city && _self.resumeData.province == 1) {
            _self.resumeData.province = 14812;
        }

        this.$set(_self.select,"selectedCity",[_self.resumeData.province, _self.resumeData.city]);
        if(getUrlParam('file') && getUrlParam('file') === 'singleEditFile') {
            if(data.unCompleteFields && data.unCompleteFields.length > 0) {
                data.unCompleteFields.forEach((key) => {
                    if(['gender', 'email', 'yearOfExperience'].indexOf(key) > -1) {
                        $(`#input-${key}`).find('.el-form-item__content').append(`
                            <div class="uncomplete-tip">请补充</div>
                        `)
                    }
                    if(key === 'major') {
                        if(!_self.resumeData.educationExperiences[0].major) {
                            $(`#education_0`).find('.major-chose').find('.el-form-item__content').append(`
                                <div class="uncomplete-tip">请补充</div>
                            `)
                        }
                    }
                    if(key === 'projectExperiences') {
                        _self.resumeData.projectExperiences.push({
                            "projectName": "",
                            "projectRole": "",
                            "description": null,
                            "responsibility": "",
                            "startDate": null,
                            "endDate": null,
                            "created": "",
                            "updated": "",
                        });
                    }
                })
            }
            this.$nextTick(() => {
                this.validBeforeSubmit(true);
            })
        }

        this.$nextTick((vm) => {
            if(this.$refs.departmentSelect && this.$refs.departmentSelect[this.currentIndex] && this.resumeData.customerDepartmentId){
                this.$refs.departmentSelect[this.currentIndex].value = this.resumeData.customerDepartmentId;
                this.$refs.departmentSelect[this.currentIndex].editInit({
                    id: this.resumeData.customerDepartmentId
                });
            }
        });
    },
    closeImportModal(){
        this.dialogImportVisible = false;
        if(this.successedCandidateIds.length>0){
            shortTips('导入成功');
            this.successedCandidateIds = [];
        }
    },
    //上传简历操作
    upload(url, formData, successFn, errorFn, callback){
        this.invokeShowLoading();
        _request({
            url: url,
            method: 'POST',
            data: formData,
            throwBusinessError: true
        }).then(res => {
            successFn(res);
        }).catch(err => {
            errorFn(err);
        }).finally(() => {
            this.invokeHideLoading();
            callback && callback();
        })
        // let xhr = new XMLHttpRequest();
        // xhr.onload = function(evt){
        //     _self.invokeHideLoading();
        //     let response = JSON.parse(xhr.response);
        //     if(response.succeeded){
        //         successFn(response);
        //     }else{
        //         errorFn(response);
        //     }
        //     callback && callback();
        // };
        // xhr.open('POST', url, true);
        // xhr.send(formData);
        // _self.invokeShowLoading();
    },
    uploadFile(event){
        let _self = this;
        _self.isUpload = true;
        let files = Array.from(event.target.files);
        if(files.length>20){
            shortTips('单次批量上传不能超过20个文件！');
            return;
        }
        if(this.isEdit && files.length>1){
            shortTips('编辑简历时只能上传一份文件！');
            return;
        }
        let formData = new FormData(this.$refs.upload);
        for(let i=0;i<files.length;i++){
            //mac文件名包含特殊符号会导致上传失败
            if(files[i] != null && files[i].name && (files[i].name.indexOf('|') > -1 || files[i].name.indexOf('>') > -1 || files[i].name.indexOf('<') > -1)) {
                var newFile = new File([files[i]], files[i].name.replace(/\||\>|\</g, ''), {type: files[i].type});
                files[i] = newFile;
            }
            formData.append('file', files[i]);
        }
        if(files.length===1){
            _self.upload(resumeUploadUrl.single_upload, formData, function(r){
                let rtnData = r;
                shortTips('解析成功');
                if(_self.isEdit){
                    //编辑时，通过附件上传，可以直接覆盖原简历的联系方式
                    rtnData.canViewContract = true;
                    rtnData.id = _self.candidateId;
                    if(!_self.isPersonalCreate) {
                        rtnData.isUploadResume = true;
                    }
                    _self.oldMobile = _self.oldMobile ? _self.oldMobile : rtnData.mobile;
                    _self.oldEmail = _self.oldEmail ? _self.oldEmail : (rtnData.email == 'xxx@xx.com' ? '' : rtnData.email);
                }else{
                }
                _self.fillData(rtnData);
                if(rtnData.mobile && rtnData.realName) {
                    _self.validateResumeRepeat(false);
                } else {
                    _self.isUpload = false;
                }
            }, function(re){
                shortTips(errorMessage(re));
            }, function(){
                _self.$refs.upload.reset();
            });
        }else{
            let existedCount = 0
            _self.showPageWaiting = true;
            _self.upload(API.MULTIPLE_UPLOAD, formData, function(r){
                _self.failedFiles = r.reasons;
                for(let i in _self.failedFiles){
                    let fileItem = _self.failedFiles[i];
                    let nameArr = fileItem.fileName.split('\\');
                    fileItem.fileName = nameArr[nameArr.length-1];
                    if(fileItem.isExisted){
                        existedCount++;
                        resumeSingleService.validMobile({ "Mobile": fileItem.candidate.mobile, "__RequestVerificationToken": _self.verificationToken })
                        .then((res)=>{
                            resumeSingleService.validEmail({ "Email": fileItem.candidate.email, "__RequestVerificationToken": _self.verificationToken }).then((res)=>{

                                }).catch((err) =>{
                                    console.log(err);
                                    //fileItem.candidateId = r.data.resumeId;
                                });
                        })
                        .catch((err)=>{
                            console.log(err)
                        })
                    }
                }
                _self.successedCount = r.successedCount;
                _self.failedCount = r.failedCount;
                _self.existedCount = existedCount;
                _self.successedCandidateIds = r.successedCandidateIds;
                _self.showPageWaiting = false;
                _self.dialogUploadVisible = true;
            }, function(re){
                shortTips(errorMessage(re));
                _self.showPageWaiting = false;
            }, function(){
                _self.$refs.upload.reset();
                _self.showPageWaiting = false;
            });
        }
    },
    closeUploadModal(){
        this.dialogUploadVisible = false;
        if(this.successedCandidateIds.length>0){
            shortTips('批量上传成功');
            this.successedCandidateIds = [];
        }
    },
    //粘贴简历
    analysisResume(){
        if(this.pasteContent===''){
            shortTips('简历内容不能为空');
            return;
        }else{
            let noHtmlText = this.pasteContent.replace(/<\/?[^>]*>/g, '');
            this.pasteLoading = true;
            resumeSingleService.parseResume({text: noHtmlText}).then((res) =>{
                let rtnData = res;
                rtnData.degree = rtnData.degree === '0' ? null : rtnData.degree;
                shortTips('解析成功！');
                this.invokeSetPastDialogVisible(false);
                this.pasteLoading = false;
                if(this.isEdit){
                    //编辑时，通过粘贴原文，也可以直接覆盖原简历的联系方式
                    rtnData.canViewContract = true;
                    rtnData.id = this.candidateId;
                    if(!this.isPersonalCreate) {
                        rtnData.isUploadResume = true;
                    }
                    this.oldMobile = this.oldMobile ? this.oldMobile : rtnData.mobile;
                    this.oldEmail = this.oldEmail ? this.oldEmail : (rtnData.email == 'xxx@xx.com' ? '' : rtnData.email);
                }else{
                }
                this.fillData(rtnData);
            }).catch((err) => {
                shortTips(err.message);
                this.pasteLoading = false;
            });
        }
    },
    selectMenu(type){
        for(let i in this.actives){
            this.actives[i] = false;
        }
        this.actives[type] = true;
        let id = '';
        switch(type){
            case 'base':
            default:
            id = 'contentBase';
            break;
            case 'experience':
            id = 'contentExperience';
            break;
            case 'education':
            id = 'contentEducation';
            break;
            case 'project':
            id = 'contentProject';
            break;
            case 'statement':
            id = 'contentStatement';
            break;
            case 'expect':
            id = 'contentIntention';
            break;
            case 'newFirstTier':
            id = 'contentNewFirstTier';
            break;
            case 'system':
            id = 'contentSystem';
            break;
        }
        this.scrollById(id);
    },
    selectCity(val){
        this.resumeData.province = val[0];
        this.resumeData.city = val[1];
    },
    selectIntentionCity(intention){
        intention.locationId = intention.locationId[1];
    },
    //根据城市获取省份id
    getProvinceByCity(cid){
        let pId = cityProvinceMapData[cid];
        return {
            id: pId
        };
    },
    validateResumeRepeat(submitImedidate){
        if(this.isEdit||this.isPersonalCreate){
            return;
        }
        let _self = this;
        let postData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        }
        resumeSingleService.resumeValidateRepeat(postData)
        .then((res)=>{
            _self.resumeData.isExisted = false;
            // 去除'该候选人已存在于人才库'的提示
            $('#input-mobile').find('.el-form-item__content .mobile-error').remove();
            if(submitImedidate){
                _self.submit();
            }
        })
        .catch((err)=>{
            console.log(err);
            let r = err;
            _self.$set(_self.resumeData,'isExisted', true);
                _self.$set(_self.resumeData,'existedId', r.data.resumeId);
                if(!r.data.resumeId){
                    _self.$set(_self.resumeData,'errorInfo', r.message);
                }
                if(!submitImedidate && !_self.file){
                    this.scrollById('contentBase');
                    shortTips('该候选人已存在于人才库');
                    _self.isUpload = false;
                    if(!!r.data.candidatId && !_self.isEdit){
                        // $('#input-mobile').find('.el-form-item__content').append(`
                        //     <div class="mobile-error">
                        //         该人才已存在于人才库
                        //         <a class="to-detail" href="/Headhunting/MyCompany.html#/candidateDetail/${r.data.candidatId}" target="_blank"><i class="el-icon-d-arrow-right"></i>去查看</a>
                        //         <a class="to-detail" id="singleEditCompare" href="javascript:;;"><i class="el-icon-d-arrow-right"></i>去对比</a>
                        //     </div>
                        // `)
                        $('#input-mobile').find('.el-form-item__content').append(`
                            <div class="mobile-error">
                                该人才已存在于人才库
                                <a class="to-detail" href="/#/candidateDetail/${r.data.candidatId}" target="_blank"><i class="el-icon-d-arrow-right"></i>去查看</a>
                                <a class="to-detail" id="singleEditCompare" href="javascript:;;"><i class="el-icon-d-arrow-right"></i>去对比</a>
                            </div>
                        `)
                        this.$nextTick(() => {
                            document.querySelector('#singleEditCompare').addEventListener('click', () => {
                                this.compareResume({
                                    fileName: this.fileName ? this.fileName : 'singleEditFile',
                                    candidateId: this.resumeData.existedId,
                                    candidate: this.resumeData,
                                    errorInfo: this.resumeData.errorInfo
                                });
                            }, {once: true});
                        })
                    }
                }else{
                    _self.compareResume({fileName: this.fileName ? this.fileName : 'singleEditFile',candidateId: _self.resumeData.existedId,candidate: _self.resumeData,errorInfo:r.message})
                }
                _self.invokeHideLoading();
        })
    },
    // 其他城市输入验证
    checkOtherCity(val) {
        let error = document.getElementsByClassName("other-city-input-error")[0];
        let input = document.getElementsByClassName("other-city-input")[0];
        if(val) {
            if(!/^([\u4E00-\u9FA5\s])+$/.test(val)) {
                error.innerHTML = "只能输入中文,多个城市以空格隔开";
                input.classList.add("adress-input-error");
                return;
            } else if(val.replace(/\s/g,'').length > 25) {
                error.innerHTML = "字数不超过25个字";
                input.classList.add("adress-input-error");
                return;
            } else{
                error.innerHTML = "";
                input.classList.remove("adress-input-error");
            }
        } else {
            error.innerHTML = "";
            input.classList.remove("adress-input-error");
        }
    },
    // validBeforeSubmit(isFromCheckPage) {
    validBeforeSubmit: debounce(function(isFromCheckPage) {
        this.invokeShowLoading();

        let _self = this;
        if(!cityProvinceMapData[this.resumeData.city]) {
            this.resumeData.province = "";
            this.resumeData.city = null;
        }
        if(this.inputSalary!==''){
            this.resumeData.expectSalary = this.inputSalary;
        }
        //同步最新工作公司和职位
        for(let i in this.resumeData.workExperiences){
            let workExperiencesItem = this.resumeData.workExperiences[i];
            if(workExperiencesItem.isLatestWorkExperience){
                this.resumeData.company = workExperiencesItem.company;

                this.resumeData.title = workExperiencesItem.title;
                if(workExperiencesItem.customerid == null || workExperiencesItem.customerid == undefined || workExperiencesItem.customerid == '0'|| workExperiencesItem.customerid == '') {
                    this.resumeData.customerId = '';
                    this.resumeData.customerDepartmentId = 0;
                } else {
                    this.resumeData.customerId = workExperiencesItem.customerid;
                    this.resumeData.customerDepartmentId = workExperiencesItem.customerDepartmentId;
                }
            }
            if(workExperiencesItem.locationArr&&workExperiencesItem.locationArr.length>0){
                workExperiencesItem.location = workExperiencesItem.locationArr[1];
            }
        }
        //同步期望城市
        for(let i in this.resumeData.intentions){
            let intentionItem = this.resumeData.intentions[i];
            if(intentionItem.locationArr&&intentionItem.locationArr.length>0){
                intentionItem.locationId = intentionItem.locationArr[1];
            }
        }
        if(this.resumeData.yearOfExperience===''){
            this.resumeData.yearOfExperience = 0;
        }
        this.$refs['resumeForm'].validate((valid) => {
            
            for(let i in _self.resumeData.intentions){
                let intentionItem = _self.resumeData.intentions[i];
                if(intentionItem.error){
                    this.scrollById('intention_' + i);
                    valid = false;
                }
            }
            if (valid) {
                if(isFromCheckPage) {
                    this.invokeHideLoading();
                    return false;
                }

                // if(this.thinkingTime && this.thinkingTime != '1') {
                //     if(this.selectNewFirstCityList.length == 0 && !this.otherCity ) {
                //         shortTips('请选择或填写考虑城市');
                //         return;
                //     }
                // }
                if(!this.thinkingTime && (this.selectNewFirstCityList.length > 0 || this.otherCity)) {
                    this.invokeHideLoading();
                    shortTips('请选择是否及考虑多久');
                    return;
                }
                // if(!this.isPersonalCreate && this.showNewFirstTier) {
                if(!this.isPersonalCreate) {
                    if(document.getElementsByClassName("other-city-input-error")[0].innerHTML != "") {
                        this.invokeHideLoading();
                        return;
                    }
                }

                // _self.invokeShowLoading();
                if(_self.isEdit || _self.isPersonalCreate){
                    _self.submit();
                }else{
                    this.invokeHideLoading();
                    _self.validateResumeRepeat(true);
                }
            } else {
                this.invokeHideLoading();
                
                document.querySelector('.resume-content .el-form-item__error') 
                && document.querySelector('.resume-content .el-form-item__error').scrollIntoView({
                    block: 'center'
                });
                // setTimeout(() =>{
                //     let errorElem = $('.el-form-item__error:first');
                //     if(errorElem.length>0){
                //         let top = $('.el-form-item__error:first').offset().top - 75;
                //         document.querySelector('.resume-upload-wrap') && (document.querySelector('.resume-upload-wrap').scrollTop = top);
                //         // if(document.body.scrollTop){
                //         //     document.body.scrollTop = top;
                //         // }else{
                //         //     document.documentElement.scrollTop = top;
                //         // }
                //         return false;
                //     }
                // });
            }
        });
    }, 300),

    changeThinkingTime(val) {
        if(val == '1') {
            this.isCitySelectShow = false;

            this.selectNewFirstCityList = [];
            this.otherCity = '';

            let error = document.getElementsByClassName("other-city-input-error")[0];
            let input = document.getElementsByClassName("other-city-input")[0];
            error.innerHTML = "";
            input.classList.remove("adress-input-error");
        } else {
            this.isCitySelectShow = true;
        }
    },

    // 获取新一线机会详情
    getNewCityExpectations(resumeId) {
        // console.log('resumeId：'+resumeId);
        let parmas = {
            resumeId: resumeId
        };
        _request({
            url: '/openapi/Candidate/GetNewCityExpectations',
            method: 'POST',
            data: parmas,
            baseURL: "LbdOpenApi",
        }).then(res => {
            console.log(res);

            if(res.id) {
                this.showWriteTip = false;
            }
            
            this.newCityExpectationsInfo = res;

            this.thinkingTime = this.newCityExpectationsInfo.expectationType.toString();
            this.otherCity = this.newCityExpectationsInfo.address;

            this.selectNewFirstCityList = [];
            this.cityList.forEach(item => {
                this.newCityExpectationsInfo.locations.forEach(el => {
                    if(el == item.value) {
                        this.selectNewFirstCityList.push(item);
                    }
                })
            })

            if(this.thinkingTime == '1') {
                this.isCitySelectShow = false;
            }
        }).catch( err => {
            console.log(err);
        });
    },
    // 创建新一线机会
    createNewCityExpectations(id) {
        let locations = this.selectNewFirstCityList.map(el => {
            return Number(el.value);
        })
        // 输入多个空格的时候，改为一个空格，再去除首尾空格
        let address = this.otherCity.replace(/\s+/g,' ').trim();
        let parmas = {
            resumeId: id,
            expectationType: this.thinkingTime,
            locations: locations,
            address: address
        };
        console.log(parmas);
        return new Promise(resolve => {
            _request({
                url: '/openapi/Candidate/CreateNewCityExpectations',
                method: 'POST',
                data: parmas,
                baseURL: "LbdOpenApi",
            }).then(res => {
                
            })
            .finally(() => {
                resolve();
            });
        })
        
    },
    // 修改新一线机会
    updateNewCityExpectations() {
        let locations = this.selectNewFirstCityList.map(el => {
            return Number(el.value);
        })
        // 输入多个空格的时候，改为一个空格，再去除首尾空格
        let address = this.otherCity.replace(/\s+/g,' ').trim();
        let parmas = {
            id: this.newCityExpectationsInfo.id,
            expectationType: this.thinkingTime,
            locations: locations,
            address: address
        };
        console.log(parmas);
        return new Promise(resolve => {
            _request({
                url: '/openapi/Candidate/UpdateNewCityExpectations',
                method: 'POST',
                data: parmas,
                baseURL: "LbdOpenApi",
            }).then(res => {
                
            })
            .finally(() => {
                resolve();
            });
        })
        
    },
    getAgeFromBirthday(birthday) {
        if(birthday == '' || birthday.indexOf('0001') == 0) {
            return '';
        }
        return (new Date()).getFullYear() - birthday.slice(0, 4);
    },
    submit(){
        this.resumeData.source = this.sourceFrom;
        this.resumeData.isPersonalCreate = this.isPersonalCreate;
        this.resumeData.yearOfExperience = parseInt(this.resumeData.yearOfExperience);

        this.resumeData.customerId = this.resumeData.customerId || 0;
        this.resumeData.customerDepartmentId = this.resumeData.customerDepartmentId || 0;

        let age = this.resumeData.age;
        if(age && this.getAgeFromBirthday(this.originBirthday) != age) {
            if(this.originBirthday.indexOf('0001') == 0) {
                let str = moment().format('-MM-DD');
                let year = parseInt(moment().format('YYYY')) - age;
                this.resumeData.birthday = year + str + 'T00:00:00';
            }else {
                let str = this.originBirthday.slice(4) || moment().format('-MM-DD');
                let year = parseInt(moment().format('YYYY')) - age;
                this.resumeData.birthday = year + str;
            }
        }

        this.invokeShowLoading();
        if(!!localStorage.getItem('cadidateDetailData')){
            localStorage.removeItem('cadidateDetailData');
        }
        if(this.isEdit){
            if (!this.resumeData.resumeId) {
                this.resumeData.resumeId = this.tbdResumeId || '';
            }
            if(this.isPersonalCreate){
                _request({
                    method: 'POST',
                    url: `/My/Candidate/${this.candidateId}/PersonalCandidateEdit`,
                    data: this.resumeData
                }).then(res => {
                    shortTips('更新成功！');
                    // location.href = `/Headhunting/MyCompany.html#/candidateDetail/${this.candidateId}`;
                    location.href = `/#/candidateDetail/${this.candidateId}`;
                    // this.dispatchAfterHold(this.candidateId);
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.invokeHideLoading();
                })
            } else {
                _request({
                    method: 'POST',
                    url: `/My/Candidate/${this.candidateId}/Edit`,
                    data: this.resumeData
                }).then(async (res) => {
                    shortTips('更新成功！');

                    if(this.thinkingTime) {
                        // 简历创建成功之后调用创建/编辑新一线机会接口
                        if(this.newCityExpectationsInfo.id) { // 编辑
                            await this.updateNewCityExpectations();
                        } else { // 创建
                            await this.createNewCityExpectations(this.resumeData.tbdResumeId);
                        }
                    }

                    if (this.isFromRecommendPage && sessionStorage.getItem('editResumeBackRecommendUrl')) {
                        location.href = sessionStorage.getItem('editResumeBackRecommendUrl');
                        return false;
                    }

                    // location.href = `/Headhunting/MyCompany.html#/candidateDetail/${this.candidateId}`;
                    location.href = `/#/candidateDetail/${this.candidateId}`;
                    // this.dispatchAfterHold(this.candidateId);
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.invokeHideLoading();
                })
            }
        } else{
            resumeSingleService.createCandidate(this.resumeData)
            .then(async (res)=>{
                if(res.isCoverResume && this.isPersonalCreate) {
                    shortTips('该人才已存在于人才库，已为你自动更新！');
                    this.invokeHideLoading();
                    setTimeout(() => {
                        window.location.href = '/Headhunting/#/talentPool';
                    }, 3000);
                } else {
                    this.invokeHideLoading();
                    shortTips('创建成功！');
                    this.resumeId = res.id;

                    if(this.thinkingTime) {
                        // 简历创建成功之后调用创建/编辑新一线机会接口
                        if(this.newCityExpectationsInfo.id) { // 编辑
                            await this.updateNewCityExpectations();
                        } else { // 创建
                            await this.createNewCityExpectations(res.id);
                        }
                    }
                    console.log(`Res:`, res)

                    if(this.isPersonalCreate) {
                        this.validateFirmExist();
                    }else{
                        // window.location.href = '/Headhunting/#/talentPool';
                        this.dispatchAfterHold(res.id);
                    }
                }
            })
            .catch((err)=>{
                this.invokeHideLoading();
                let errorMessage = [];
                if(err.message == '简历重复') {
                    shortTips("创建失败，个人库已经有相同简历");
                } 
                // else if(err.data) {
                //     if(err.data.isFirmExisted && this.isPersonalCreate) {
                //         this.$set(this.resumeData,'isExisted', true);
                //         this.$set(this.resumeData,'existedId', err.data.id);
                //         this.scrollById('contentBase');
                //         shortTips('该候选人已存在于人才库');
                //         if(err.data.id){
                //             $('#input-mobile').find('.el-form-item__content').append(`
                //                 <div class="mobile-error">
                //                     该人才已存在于人才库
                //                     <a class="to-detail" href="/#/candidateDetail/${err.data.id}" target="_blank"><i class="el-icon-d-arrow-right"></i>去查看</a>
                //                     <a class="to-detail" id="singleEditCompare" href="javascript:;;"><i class="el-icon-d-arrow-right"></i>去对比</a>
                //                 </div>
                //             `)
                //             this.$nextTick(() => {
                //                 document.querySelector('#singleEditCompare').addEventListener('click', () => {
                //                     this.compareResume({
                //                         fileName: this.fileName ? this.fileName : 'singleEditFile',
                //                         candidateId: this.resumeData.existedId,
                //                         candidate: this.resumeData,
                //                         errorInfo: err.message
                //                     });
                //                 }, {once: true});
                //             })
                //         }
                //     } else {
                //         Object.keys(err.data).forEach(item => {
                //             if(err.data[item]) {
                //                 err.data[item].forEach(item => {
                //                     errorMessage.push(item);
                //                 })
                //             }
                //         })
                //         shortTips(errorMessage.join(", "));
                //     }
                // } 
                else {
                    shortTips(err.message);
                }
            })
            .finally(() => {
                this.invokeHideLoading();
            })
        }
    },

    // 分发 打开后 的操作
    dispatchAfterHold(id) {
        // afterOpenRadio
        localStorage.setItem('latestAfterHold', this.$parent.afterOpenRadio);
        console.log('afterOpenRadio:', this.$parent.afterOpenRadio)
        switch(this.$parent.afterOpenRadio) {
            case '1':
                window.location.href = '/Headhunting/#/talentPool';
                break;
            case '2':
                window.location.href = `/#/candidateDetail/${id}`
                break;
            case '3':
                if(this.isPersonalCreate) {
                    window.location.href = `/?type=0#/resumeUpload/singleUpload`;
                } else {
                    window.location.href = `/?type=1#/resumeUpload/singleUpload`;
                }
                setTimeout(() => {
                    window.location.reload();
                }, 300);
                break;
            default: 
                break;
        }
    },

    cancel() {
        if(this.isEdit) {
            // location.href = `/Headhunting/MyCompany.html#/candidateDetail/${this.candidateId}`;
            location.href = `/#/candidateDetail/${this.candidateId}`;
        } else {
            // location.href = `/Headhunting/MyCompany.html#${this.$store.state.candidateListUrl.candidateListUrl}`;
            location.href = `/#${this.$store.state.candidateListUrl.candidateListUrl}`;
        }
    },
    getCandidateStatus() {
        window.createVue = this;
        _request({
            method: 'GET',
            url: `/CandidateLock/${getUrlParam('candidateId')}/IsOfferStamp`
        }).then(res => {
            this.isLocked = res.isOnboardLock;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
        })
    },
    handleDelEducation(index){
        this.delIndex = index;
        this.delDialogVisible = true;
        this.dialogType = 'education';
    },
    handleDelExperience(index){
        this.delIndex = index;
        this.delDialogVisible = true;
        this.dialogType = 'experience';
    },
    handleDelProject(index){
        this.delIndex = index;
        this.delDialogVisible = true;
        this.dialogType = 'project';
    },
    handleDelIntention(index){
        this.delIndex = index;
        this.delDialogVisible = true;
        this.dialogType = 'intention';
    },
    handleDelStatement(){
        this.delDialogVisible = true;
        this.dialogType = 'statement';
    },
    delCallback(){
        switch (this.dialogType) {
            case 'education':
                this.removeEducation(this.delIndex);
                break;
            case 'experience':
                this.removeExperience(this.delIndex);
                break;
            case 'project':
                this.removeProject(this.delIndex);
                break;
            case 'intention':
                this.removeIntention(this.delIndex);
                break;
            case 'statement':
                this.removeStatement();
                break;
            default:
                break;
        }
        this.delDialogVisible = false;
    },
    delAllCallback(){
        switch (this.dialogType) {
            case 'education':
                this.removeAllEducation();
                break;
            case 'project':
                this.removeAllProject();
                break;
            case 'intention':
                this.removeAllIntention();
                break;
            case 'statement':
                this.removeStatement();
                break;
            // case 'newFirstTier':
            //     this.removeNewFirstTier();
            //     break;
            case 'system':
                this.removeSystem();
                break;
            case 'experience':
                this.removeAllExperience();
                break;
            default:
                break;
        }
        this.delAllDialogVisible = false;
    },
    handleDelAll(type){
        this.dialogType = type;
        this.delAllDialogVisible = true;
    },
    handleClosePaste(){
        this.pasteLoading = false;
        this.invokeSetPastDialogVisible(false);
    },
    scrollById(id){
        console.log(document.getElementById(id).offsetTop);
        let offestTop = document.getElementById(id).offsetTop;
        console.log(document.documentElement.scrollTop);
        console.log(document.querySelector('.resume-upload-container').scrollTop);
        // document.documentElement.scrollTop = offestTop;
        document.querySelector('.resume-upload-container').scrollTop = offestTop;
    },
    validateFirmExist() {
        //个人库在提交后判断是否存在于企业库
        let postData = {
            realName: this.resumeData.realName,
            mobile: this.resumeData.mobile,
            email: this.resumeData.email,
            gender: this.resumeData.gender
        }
        resumeSingleService.resumeValidateRepeat(postData)
            .then((res)=>{
                this.toFirmDialogVisible = true;
            })
            .catch((err)=>{
                console.log(err)
                // window.location.href = '/Headhunting/#/talentPool';
                this.dispatchAfterHold(this.resumeId)
            });
    },
    handleCancelToFirm(){
        // window.location.href = '/Headhunting/#/talentPool';
        this.dispatchAfterHold(this.resumeId)
    },
    handleToFirm(){
        this.addToCompany(this.resumeId);
    },
    //导入企业库 请求函数
    importToFirm(id) {
        let resumeIds = [];
        resumeIds.push(id);
        return talentPoolService.importToFirm({
            tbdResumeIds: resumeIds
        });
    },
    addToCompany(id) {
        this.$confirm('确定导入到企业人才库？', '提示', {
            showClose: false,
            cancelButtonText: '取消',
            confirmButtonText: '确定'
        }).then(() => {
            this.firmLoading = true;
            this.importToFirm(id).then((res) => {
                if(res) {
                    //TODO
                    this.toFirmDialogVisible = false;
                    this.firmLoading = false;
                    this.$refs.importResultDialog.show(res);
                }
            }).catch((err) => {
                console.log(err);
                this.firmLoading = false;
            });
        }).catch(() => {});
    },
    handleFirmConfirm(){
        window.location.href = '/Headhunting/#/talentPool';
    },
    soFarDate(date){
        if(date&&date.indexOf('9999-12-31')>-1){
            return new Date();
        }else{
            return date;
        }
    },
    //日期控件配置
    setExperienceStartDateOption(experience){
        experience.startDate = experience.startDate==='' ? null : experience.startDate;
        experience.endDate = experience.endDate==='' ? null : experience.endDate;
        if(typeof experience.endDate==='string'){
            experience.endDate = new Date(experience.endDate.split('T')[0]);
        }
        return {
            disabledDate: (time) => {
                if (!!experience.endDate) {
                    return time.getTime() > Date.now() || time.getTime() > experience.endDate.getTime();
                } else {
                    return time.getTime() > Date.now();
                }
            }
        }
    },
    setExperienceEndDateOption(experience){
        experience.startDate = experience.startDate === '' ? null : experience.startDate;
        experience.endDate = experience.endDate === '' ? null : experience.endDate;
        if(typeof experience.startDate==='string'){
            experience.startDate = new Date(experience.startDate.split('T')[0]);
        }
        let _this = this;
        return {
            disabledDate: (time) => {
                if (!!experience.startDate) {
                    return time.getTime() < experience.startDate.getTime() || time.getTime() > Date.now();
                }
            },
            shortcuts: [{
                text: '至今',
                onClick(picker) {
                    let date = new Date('9999-12-31');
                    picker.$emit('pick', date);

                    if (experience.startDate || experience.endDate) {
                        experience.dateRange = [experience.startDate || '', experience.endDate || ''];
                    } else {
                        experience.dateRange = '';
                    }
                }
            },{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
            }]
        }
    },
    setEducationStartDateOption(experience){
        experience.startDate = experience.startDate==='' ? null : experience.startDate;
        experience.endDate = experience.endDate==='' ? null : experience.endDate;
        if(typeof experience.endDate==='string'){
            experience.endDate = new Date(experience.endDate.split('T')[0]);
        }
        return {
            disabledDate: (time) => {
                if (!!experience.endDate) {
                    return time.getTime() > experience.endDate.getTime();
                }
            }
        }
    },
    setEducationEndDateOption(experience){
        experience.startDate = experience.startDate==='' ? null : experience.startDate;
        experience.endDate = experience.endDate==='' ? null : experience.endDate;
        if(typeof experience.startDate==='string'){
            experience.startDate = new Date(experience.startDate.split('T')[0]);
        }
        return {
            disabledDate: (time) => {
                if (!!experience.startDate) {
                    return time.getTime() < experience.startDate.getTime();
                }
            },
            shortcuts: [{
                text: '至今',
                onClick(picker) {
                    let date = new Date('9999-12-31');
                    picker.$emit('pick', date);

                    if (experience.startDate || experience.endDate) {
                        experience.dateRange = [experience.startDate || '', experience.endDate || ''];
                    } else {
                        experience.dateRange = '';
                    }
                }
            },{
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date());
                }
            }]
        }
    },
    dateBlur(picker, experience, type) {
        if (experience.startDate || experience.endDate) {
            experience.dateRange = [experience.startDate || '', experience.endDate || ''];
        } else {
            experience.dateRange = '';
        }
    },
    goToCompare(resume) {
        if(resume && resume.repeatResumeId) {
            window.open(`/My/Candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
        } else {
            shortTips("系统异常，请稍后重试");
        }
    },

    ...mapActions('resumeUpload',[
        'invokeShowLoading',
        'invokeHideLoading',
        'invokeSetSourceFrom',
        'invokeSetPastDialogVisible'
    ])
    }
};
</script>

<style lang="scss">
    @import './singleUpload.scss';
    .el-date-picker{
        width: auto !important;
    }
    .menu-resume{
        color: #444;
        li{
            font-size: 14px;
        }

        .abled{
            .font-icon{
                fill: #666;
            }
        }
        .disabled{
            .font-icon{
                fill: #d2d2d2;
            }

        }
        .active{
            .font-icon{
                fill: $primary;
            }
        }
    }
    .single-resume-upload{
        .font-icon{
            width: 16px;
            height: 16px;
            fill: #999;
        }
        .delete-btn{
            &:hover{
                .font-icon{
                    fill: #FC7859;
                }
            }
        }
    }
    .gender-select-btn{
        background: #fff !important;
        &.active{
            background: $primary !important;
            color: #fff !important;
        }
    }
    .date-picker-wrap{
        display: inline-block;
        width: 95px;
        position: relative;
        .el-date-editor{
            .el-input__prefix{
                display: none;
            }
            .el-input__inner{
                text-align: center;
                &:hover, &:focus {
                    border: none;
                }
            }
        }
        .el-input--prefix .el-input__inner{
            padding-left: 10px;
        }
        .el-input--suffix .el-input__inner{
            padding-right: 10px;
        }

    }
    .date-picker-split{
        padding: 0 6px;
    }
    .opacity-date-picker{
        .el-input__inner{
            opacity: 0;
            z-index: 2;
        }
    }
    .date-picker-pannel{
        display: inline-block;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
        padding-left: 10px;
        .el-input__inner{
            border: none!important;
        }

    }
    .page-content .content-main .date-picker-pannel .el-input{
        width: 100%;
    }

    .el-form-item.recommend-required .el-form-item__label .required-mark-wrap {
        display: inline-flex;
        align-items: center;

        .required-mark {
            font-size: 12px;
            color: #F56C6C;  
            margin-right: 4px; 
        }
    }

    .el-form-item.yexp-form-item {
        .el-form-item__content .el-input {
            .el-input__inner {
                border-right: 0;
                &:focus + .el-input-group__append {
                    border-color: #38bc9d;
                }
            }
            &.el-input-group {
                vertical-align: initial;
            }
            .el-input-group__append {
                background: inherit;
            }
        }
        &.is-error .el-input-group__append {
            border-color: #F56C6C!important;
        }
        &.is-error .el-input-group--append:hover .el-input-group__append {
            border-color: #DCDFE6!important;
        }
        &.is-success .el-input__inner:focus + .el-input-group__append {
            border-color: $primary;
        }
        &.is-error .el-input-group--append:hover .el-input__inner:focus + .el-input-group__append {
            border-color: #F56C6C!important;
        }
        // &.is-error .el-input__inner:focus + .el-input-group__append {
        //     border-color: #ddd;
        // }
    }
    .content-intention{
        .min-salary-input,.max-salary-input{
            position: relative;
            width: 100px;
            &:after{
                position: absolute;
                display: block;
                content: 'k';
                right: 10px;
                top: 2px;
                color: #999;
            }
        }
        .max-salary{
            .el-form-item__content{
                margin-left: 0 !important;
            }
        }
    }
    .content-statement{
        .el-form-item__content{
            margin-left: 0 !important;
        }
        .el-textarea{
            // width: 716px !important;
            margin-left: 130px;
        }
    }
    .content-newfirsttier {
        .new-first-tier-tip {
            font-size: 14px;
            color: #FA765D;
            padding: 0 20px;
            margin-bottom: 48px;
        }

        .thinking-time-item {
            display: flex;

            .write-tip {
                position: relative;
                display: flex;
                align-items: center;
                margin-left: 26px;
                padding: 0 10px;
                height: 28px;
                line-height: 28px;
                background-color: #FA765D;
                font-size: 12px;
                color: #fff;
                border-radius: 2px;
                .triangle {
                    position: absolute;
                    left: -12px;
                    width: 0;
                    height: 0;
                    border-right: 6px solid #FA765D;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 6px solid transparent;
                }
                i {
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: 18px;
                }
            }
        }
        .thinking-city-item {
            .city-list {
                // width: 840px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                li {
                    cursor: pointer;
                    margin-right: 28px;
                    margin-bottom: 20px;
                    width: 48px;
                    height: 24px;
                    line-height: 24px;
                    background-color: #fff;
                    color: #38bc9d;
                    text-align: center;
                }
                li.selected {
                    background-color: #38bc9d;
                    color: #fff;
                }
            }
            .other-city-input {
                // width: 820px;
                width: 100%;
                &.adress-input-error {
                    .el-input__inner {
                        border-color: #ff493c !important;
                    }
                }
            }
            .other-city-input-error {
                color: #ff493c;
            }
        }
        .prompt-message {
            margin: 20px 0 0 130px;
            font-size: 12px;
            color: #999999;
        }
    }
    .content-submit{
        .el-form-item__content{
            margin-left: 0 !important;
        }
    }
    .resumeData-department-select{
        margin-top: -6px;
    }
    .department-tip{
        // display: block;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-top: 4px;
    }

    .score-feedback-dialog .tip {
        font-size: 14px;
        color: #333;

        i {
            color: red;
            margin: 0 4px;
        }
    }
</style>
